.accordion .btn {
    height: 20px;
    margin-top: -20px;
}

.Countdown {
    margin: 10px auto;
    padding-bottom: 20px;
}

.Countdown-col {
    display: inline-block;
}

.Countdown-col-element {
    display: inline-block;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
}

.Countdown-col-element strong {
    font-size: 30px;
}

.zeor21logo {
    font-size: 10pt;
    text-align: right;
    margin-top: -10px;
    margin-bottom: 0px;
}