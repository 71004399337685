
/* ------------------------------------------------
  Project:   Bootsland - Creative Bootstrap 4 Landing Page
  Author:    ThemeHt
------------------------------------------------ */

@import url('https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

body {font-family: 'Poppins', sans-serif; font-style: normal; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #1d1d33; overflow-x: hidden;}
.page-wrapper { overflow-x: hidden; }
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { margin-bottom: 0.5rem; font-weight: 300; line-height: 1.5; }
h1, .h1 { font-size: 2.5rem; }
h2, .h2 { font-size: 2rem; }
h3, .h3 { font-size: 1.75rem; }
h4, .h4 { font-size: 1.5rem; }
h5, .h5 { font-size: 1.25rem; }
h6, .h6 { font-size: 1rem; }
h4, h5, h6, .h4, .h5, .h6 { font-weight: 500; }
p { color: #8090b5; line-height: 1.7; }
.lead { font-size: 1.25rem; font-weight: 300; }
.text-dark{color: #1d1d33 !important;}
.text-primary {color: #213262 !important;}
.text-muted { color: rgb(140, 152, 164) !important }
section{padding: 5rem 0}

/* ------------------------
Transition
------------------------*/
a, i, .btn, button, img, span, .dropdown-item, .hover-translate { -webkit-transition: all 0.5s ease-in-out 0s; -moz-transition: all 0.5s ease-in-out 0s; -ms-transition: all 0.5s ease-in-out 0s; -o-transition: all 0.5s ease-in-out 0s; transition: all 0.5s ease-in-out 0s; }
.hover-translate:hover { box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15); background-color: #fff; -webkit-transform: translate3d(0, -20px, 0); -moz-transform: translate3d(0, -20px, 0); transform: translate3d(0, -20px, 0); }
a:hover { text-decoration: none; }


/* ------------------------
Background Overlay
------------------------*/
[data-overlay] { position: relative; z-index: 0; }
[data-overlay]:before { position: absolute; content: ''; background-color: #000000; width: 100%; height: 100%; top: 0; left: 0; z-index: -1; }
[data-overlay].white-overlay:before { background-color: #fff; }
[data-overlay].theme-overlay:before { background-color: #213262; }
[data-overlay].grediant-overlay:before { background: rgb(3, 214, 101); background: linear-gradient(58deg, rgba(3, 214, 101, 1) 15%, rgba(115, 41, 194, 1) 100%); }
[data-overlay="1"]:before { opacity: 0.1; }
[data-overlay="2"]:before { opacity: 0.2; }
[data-overlay="3"]:before { opacity: 0.3; }
[data-overlay="4"]:before { opacity: 0.4; }
[data-overlay="5"]:before { opacity: 0.5; }
[data-overlay="6"]:before { opacity: 0.6; }
[data-overlay="7"]:before { opacity: 0.7; }
[data-overlay="8"]:before { opacity: 0.8; }
[data-overlay="9"]:before { opacity: 0.9; }
[data-overlay="10"]:before { opacity: 1; }
[data-overlay="0"]:before { opacity: 0; }


.font-w-3 { font-weight: 300; }
.font-w-4 { font-weight: 400; }
.font-w-5 { font-weight: 500; }
.font-w-6 { font-weight: 600; }
.font-w-7 { font-weight: 700; }
.font-w-8 { font-weight: 800; }
.font-w-9 { font-weight: 900; }



/* ------------------------
Left-Right
------------------------*/
.left-0 { left: 0; }
.right-0 { right: 0; }
.top-0 { top: 0; }
.bottom-0 { bottom: 0; }
.left-50 { left: 50%; transform: translateX(-50%); -webkit-transform: translateX(-50%); -o-transform: translateX(-50%); -ms-transform: translateX(-50%); -moz-transform: translateX(-50%); }
.top-50 { top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); }


/* ------------------------
Pre Loader
------------------------*/
#ht-preloader { background: #ffffff; bottom: 0; height: 100%; left: 0; overflow: hidden !important; position: fixed; right: 0; text-align: center; top: 0; width: 100%; z-index: 99999; }
.clear-loader { transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); -ms-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); z-index: 999; box-sizing: border-box; display: inline-block; left: 50%; position: absolute; text-align: center; top: 50%; }
.loader-inner { --path: #213262; --dot: #5628ee; --duration: 3s; width: 88px; height: 88px; position: relative; }
.loader-inner:before { content: ''; width: 12px; height: 12px; border-radius: 50%; position: absolute; display: block; background: #f94f15; top: 74px; left: 38px; transform: translate(-36px, -36px); animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite; }
.loader-inner svg { display: block; width: 100%; height: 100%; }
.loader-inner svg rect { fill: none; stroke: var(--path); stroke-width: 10px; stroke-linejoin: round; stroke-linecap: round; }
.loader-inner svg rect { stroke-dasharray: 192 64 192 64; stroke-dashoffset: 0; animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite; }
@keyframes pathRect {
	25% { stroke-dashoffset: 64; }
	50% { stroke-dashoffset: 128; }
	75% { stroke-dashoffset: 192; }
	100% { stroke-dashoffset: 256; }
}
@keyframes dotRect {
	25% { transform: translate(0, 0); }
	50% { transform: translate(36px, -36px); }
	75% { transform: translate(0, -72px); }
	100% { transform: translate(-36px, -36px); }
}


/* ------------------------
Button
------------------------*/
.btn { font-weight: 500; padding: 0.75rem 1rem; }
.btn-primary { color: #fff; background-color: #213262; border-color: #213262; }
.btn-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle { color: #fff; background-color: #f94f15; border-color: #f94f15; }
.btn-outline-primary { color: #213262; border-color: #213262; }
.btn-outline-primary:hover { background-color: #f94f15; border-color: #f94f15; }
.btn-dark { background-color: #060927; border-color: #060927; }
.btn-dark:hover { background-color: #f94f15; border-color: #f94f15; }
.btn.focus, .btn:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus { box-shadow: none; }
button:focus { outline: none; }

/* ------------------------
Background 
------------------------*/
.bg-primary { background-color: #212227 !important; }
.bg-light { background-color: #f5faff !important; }
.bg-dark { background-color: #060927 !important; }
.bg-orange { background-color: #f94f15 !important }
.bg-primary-soft { background-color: rgba(19, 96, 239, 0.01); }
.bg-warning-soft { background-color: rgba(250, 215, 118, .1); }


/* ------------------------
Badge
------------------------*/
.badge-dark { background-color: #060927 !important; }
.badge-primary-soft { background-color: rgba(19, 96, 239, .1); color: #213262 }
.badge-secondary-soft { background-color: rgba(80, 102, 144, .1); color: #506690 }
.badge-success-soft { background-color: rgba(40, 167, 69, .1); color: #28a745 }
.badge-info-soft { background-color: rgba(23, 162, 184, .1); color: #17a2b8 }
.badge-warning-soft { background-color: rgba(255, 193, 7, .1); color: #ffc107 }
.badge-danger-soft { background-color: rgba(220, 53, 69, .1); color: #dc3545 }
.badge-light-soft { background-color: rgba(245, 250, 255, .1); color: #f5faff }
.badge-dark-soft { background-color: rgba(6, 9, 39, .1); color: #060927 }


/* ------------------------
Icon
------------------------*/
.f-icon-shape-sm { width: 4rem; height: 4rem; line-height: 4rem; display: inline-block; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; text-align: center; }
.f-icon-shape { width: 6rem; height: 6rem; line-height: 6rem; display: inline-block; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; text-align: center; }
.ic-2x { font-size: 2em; }
.ic-3x { font-size: 3em; }
.ic-5x { font-size: 5em; }
.ic-7x { font-size: 7em; }

/* ------------------------
Shadow
------------------------*/
.shadow-primary { -webkit-box-shadow: 0 0.5rem 1rem rgba(110, 0, 255, .15) !important; box-shadow: 0 0.5rem 1rem rgba(110, 0, 255, .15) !important; }
.shadow-sm { box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important; }
.shadow { box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15) !important; }
.shadow-lg { box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important; }
.shadow-none { box-shadow: none !important; }


/* ------------------------
  Header
------------------------*/
.site-header.navbar-dark .navbar-nav .nav-link { color: #ffffff; }
.site-header.navbar-dark .navbar-nav .nav-link:focus, .site-header.navbar-dark .navbar-nav .nav-link:hover { color: #f94f15; }
#header-wrap { padding: 10px 0; position: relative; }
#header-wrap.fixed-header { padding: 5px 0; position: fixed !important; top: 0; left: 0; width: 100%; z-index: 999 !important; background: #ffffff !important; box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15) !important; animation: smoothScroll 1s forwards; }

/*--navigation--*/
.navbar { padding: 0; }
.navbar-nav .nav-item.dropdown .dropdown-menu { background: #ffffff; padding: 0; border: none; margin-top: 1rem; -webkit-box-shadow: 0 0.5rem 1rem rgba(110, 0, 255, .15) !important; box-shadow: 0 0.5rem 1rem rgba(110, 0, 255, .15) !important; }
.navbar-brand { margin-right: 0; }
.navbar-brand { margin-right: 0; }
header .navbar-nav .nav-item { margin: 0 15px }
.navbar-nav .nav-link { font-weight: 500; padding: 0 !important; border-radius: 0; text-transform: capitalize; position: relative; }
.navbar-dark #header-wrap.fixed-header { background: #213262 !important }
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .show > .nav-link, .dropdown-item:focus, .dropdown-item:hover { color: #213262; }

.dropdown-item:focus, .dropdown-item:hover{background: #213262; color: #ffffff;}
.navbar-dark .navbar-nav .nav-link {color: rgba(255,255,255,.8);}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {color: rgba(255,255,255,1);}
.dropdown-submenu { position: relative; }
.dropdown-submenu a::after { transform: translateY(-50%) rotate(-90deg); position: absolute; right: 1.5rem; top: 50%; }
.dropdown-submenu .dropdown-menu { top: 0; left: 100%; margin-left: .1rem; margin-right: .1rem; margin-top: 0 !important;}
.dropdown-submenu .dropdown-item { padding-right: 2.5rem; position: relative; }
.dropdown-item { padding: .50rem 1.5rem; font-weight: 400; color: #8090b5; font-size: 0.9rem }

@media (max-width:991.98px) {
/*Header*/
#header-wrap { padding: 15px 0; }
#header-wrap.fixed-header { padding: 15px 0; }
.navbar { justify-content: flex-end; width: 100%; position: relative !important; }
.navbar-brand { left: 15px; z-index: 1; margin-right: 0; position: absolute; top: 50%; padding: 0; transform: translateY(-50%); -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); }
.navbar-toggler { background: none; height: 40px; width: 40px; padding: 0; border-radius: 0; margin-right: 0; }
.site-header .navbar-nav ul { position: static; }
.site-header .navbar-nav .nav-item { margin: 0; }
.site-header .navbar-nav .nav-link { padding: 10px !important; border-radius: 0; }
.navbar-nav a .sub-arrow { position: absolute; top: 50%; right: 0; margin: -0.7em 0.5em 0 0; border-radius: .25rem; padding: 0; width: 2em; height: 1.4em; font-size: 1.25rem; line-height: 1.2em; text-align: center; }
.navbar-collapse { border-radius: 7px; background: #ffffff; max-height: 400px; left: 0; padding: 10px; position: absolute; z-index: 99; top: 50px; width: 100%; overflow: auto; border: medium none; -webkit-box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15); -moz-box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15); box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15); }
.navbar-nav .dropdown-toggle::after { position: absolute; top: 50%; right: 0; margin: -.1rem 0.5em 0 0; }
.site-header.navbar-dark .navbar-nav .nav-link{color: rgba(0,0,0,.5)}
.site-header.navbar-dark .navbar-nav .nav-link.active, .site-header.navbar-dark .navbar-nav .nav-link:focus, .site-header.navbar-dark .navbar-nav .nav-link:hover{ color: #213262; }
}


.shape-1 { position: absolute; top: -1px; width: 100%; left: 0; right: 0; }
.shape-2 { position: absolute; right: 0; top: 0; height: 100%; width: 100%; }
.shape-1.bottom { top: inherit; bottom: -1px; }
.round-shape { clip-path: circle(100% at 50% -50%); }

/* ------------------------
Contact
------------------------*/
.form-control { height: 3rem; font-size: 0.8rem; }
.input-1 { border: 1px solid #d0d7e7; }
.input-2 { border: none; background: none; box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15) !important; }
.form-control:focus { color: #495057; background-color: none; border-color: #213262; outline: 0; box-shadow: none; }
.form-group { margin-bottom: 1.3rem; position: relative; }
.help-block.with-errors { position: absolute; color: red; right: 15px; font-size: 10px; line-height: 14px; }


/* ------------------------
Tab
------------------------*/
.nav-tabs .nav-item.nav-link { padding: 1.5rem 2rem; font-size: 1.2rem; color: #1d1d33; margin: 0 5px; border-radius: .25rem; box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15) !important; }
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover { background: #213262; color: #ffffff; }
@media (max-width:575.98px) {
	.nav-tabs .nav-item.nav-link { padding: .5rem 1rem; }
}


/* ------------------------
Video
------------------------*/
.video-btn { position: relative; display: inline-block; }
.play-btn { font-size: 30px; background: #ffffff; color: #213262; line-height: 60px; width: 70px; height: 70px; border: 10px solid #ffffff; -webkit-border-radius: 100%; border-radius: 100%; text-align: center; cursor: pointer; z-index: 9; position: relative; display: inline-block; -webkit-transition: .15s ease-in-out; transition: .15s ease-in-out; -webkit-transition-property: color, background, border-color; transition-property: color, background, border-color; }
.video-btn-pos { position: absolute; position: absolute; left: 50%; top: 50%; width: auto !important; z-index: 99; transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); -ms-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); }
.html5-video { height: 100%; left: 0; opacity: 1; overflow: hidden; position: absolute; top: 0; width: 100%; z-index: -2; }
.html5-video video { width: 100%; }
.video-btn .spinner-eff { position: absolute; width: 200px; height: 200px; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); -ms-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); }

/* ------------------------
Spinner
------------------------*/
.spinner-eff .spinner-circle { border-radius: 100px; position: absolute; left: 0; right: 0; margin: auto; transform: scale(1); transform-origin: center center; }
.spinner-eff .circle-1 { width: 100%; height: 100%; background-color: #213262; top: 0; animation: pulse 1.6s linear 0s infinite; }
.spinner-eff .circle-2 { width: 66.6%; height: 66.6%; background-color: #213262; top: 16.5%; animation: pulse-2 1.6s linear 0s infinite; }

/* ------------------------
Portfolio
------------------------*/
.portfolio-filter { display: inline-block; margin: 0 auto 2rem; text-align: center; }
.portfolio-filter button { position: relative; background: none; padding: 8px 15px; border-radius: .25rem; cursor: pointer; border: none; font-weight: normal; color: #1d1d33; float: left; text-transform: capitalize; }
.portfolio-filter button:focus { outline: none; outline-style: none; outline-offset: 0; }
.portfolio-filter button.is-checked, .portfolio-filter button:hover { background: #213262; color: #ffffff; box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15) !important; }
.portfolio-filter button + button { margin-left: 0.5rem; }
.isotope .grid-item img { width: 100%; }
.portfolio-title { background: #213262; padding: 30px; width: 100%; -webkit-transform: translate3d(0, 100%, 0); -ms-transform: translate3d(0, 100%, 0); transform: translate3d(0, 100%, 0); opacity: 0; visibility: hidden; -webkit-transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; position: absolute; left: 0; bottom: 0; z-index: 2; }
.portfolio-item:hover .portfolio-title { opacity: 1; visibility: visible; -webkit-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
.portfolio-meta li { position: relative; padding-left: 60px; }
.portfolio-meta li i { position: absolute; left: 0; top: 0; color: #213262; font-size: 40px; line-height: 40px; }
.portfolio-meta li span { color: #1d1d33; display: block; font-weight: bold; text-transform: uppercase; margin-bottom: 7px; }

/* -------------------------------
 Background Position & Size
-------------------------------*/
[data-bg-img] { background-size: cover; background-position: center center; background-repeat: no-repeat; }
.bg-cover { background-size: cover; }
.bg-contain { background-size: contain; }
.bg-pos-l { background-position: left; }
.bg-pos-r { background-position: right; }
.bg-pos-rt { background-position: right top; }
.bg-pos-lt { background-position: left top; }
.bg-pos-rb { background-position: right bottom; }
.bg-pos-lb { background-position: left bottom; }
.bg-repeat { background-repeat: repeat; }
.bg-pos-b { background-position: bottom; }
.heroparallax {
    position: absolute;
    bottom: 60px;
    right: -10%;
    width: 50%;
}
.simpleParallax{overflow: inherit !important;}


.product-link a.add-cart { width: auto; height: auto; padding: 0px 20px; line-height: 36px; font-size: 14px; }
.product-link a i { display: inline-block; color: #000; font-size: 18px; vertical-align: middle; }
.product-link a { display: inline-table; width: 36px; height: 36px; background: #fff; border-radius: 60px; line-height: 36px; overflow: hidden; color: #000; position: relative; -webkit-box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15); box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15); -webkit-transition: all .4s ease; -o-transition: all .4s ease; transition: all .4s ease; text-align: center; }
.product-link a:hover { background: #213262; color: #ffffff; }
.product-link a:hover i { color: #ffffff; }
.size-choose input[type="radio"] { visibility: hidden; position: absolute; }
.size-choose label { display: inline-block; height: 30px; min-width: 30px; text-align: center; font-size: 14px; color: #1d1d33; font-weight: 500; cursor: pointer; border-radius: 50%; line-height: 30px; }
.size-choose input[type="radio"]:checked + label { background: #213262; color: #fff; }
.filter-color li, .size-choose li { list-style-type: none; display: inline-block; margin-right: 10px; }
.filter-color li:last-child, .size-choose li:last-child { margin-right: 0; }
[type="radio"]:checked, [type="radio"]:not(:checked) { position: absolute; opacity: 0; pointer-events: none; }
.form-check-label { width: 26px; height: 26px !important; border-radius: 50%; padding: 0 !important; }
.form-check-input[type="radio"]:checked + label, .form-check-input[type="radio"]:not(:checked) + label, label.btn input[type="radio"]:checked + label, label.btn input[type="radio"]:not(:checked) + label { position: relative; padding-left: 35px; cursor: pointer; display: inline-block; height: 1.5625rem; line-height: 1.5625rem; transition: .28s ease; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.form-check-input[type="radio"] + label::after, .form-check-input[type="radio"] + label::before, label.btn input[type="radio"] + label::after, label.btn input[type="radio"] + label::before { content: ''; position: absolute; left: -2px; top: -2px; width: 30px; height: 30px; z-index: 0; transition: .28s ease; z-index: -1; border-radius: 50%; }
.form-check-input[type="radio"]:checked + label::after, label.btn input[type="radio"]:checked + label::after { transform: scale(1.02); }
.form-check-input[type="radio"]:checked + label::after { background-color: #213262; }
input.form-product { -moz-appearance: textfield; height: 40px; border: none; background: #ffffff; text-align: center; width: 150px; border-top: 1px solid #eeeff7; border-bottom: 1px solid #eeeff7; color: #1d1d33; vertical-align: middle; }
.btn-product { background: none; color: #1d1d33; border: 1px solid #eeeff7; height: 40px; width: auto; padding: 0 10px; font-size: 13px; cursor: pointer; }

/*Range Slider*/
.ui-slider-horizontal { height: 0.2em; }
.ui-slider-horizontal .ui-slider-handle { top: -6px; height: 14px; width: 14px; border-radius: 50%; border: none; margin-left: 0; }
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active { background: #213262; }
.ui-widget-header { background: #0a1b2b }
.ui-widget.ui-widget-content { border: none; background: #f5faff; }
#amount { margin-top: 20px; width: 90px; color: #1d1d33 }
.cart-table thead th { padding: 30px; text-align: center; border-width: 1px !important; vertical-align: middle; color: #1d1d33; font-size: 18px; font-weight: 500; }
.cart-table td { padding: 20px; text-align: center; vertical-align: middle; color: #1d1d33; }


/* ------------------------
Countdown
------------------------*/
.countdown { margin: 50px 0; width: 100%; }
.countdown > li { font-weight: 700; list-style-type: none; }
.countdown > li span { font-size: 70px; font-weight: 700; line-height: 80px; display: inline-block; color: #213262; }
.countdown > li p { font-size: 20px; display: block; line-height: 30px; margin-bottom: 0; color: #1d1d33; }

/* ------------------------
Other
------------------------*/
.clients-logo img { opacity: 0.5; max-height: 50px; }
.clients-logo img:hover { opacity: 1; }
#particles-js {width: 100%; height: 100%; position: absolute; top: 0; left: 0;}
.list-group-item-action:focus, .list-group-item-action:hover {color: #213262; background-color: inherit;}
.page-link:hover {color: #ffffff; background-color: #213262; border-color: #213262;}
.link-title { color: #1d1d33 }
.link-title:hover { color: #213262 }
.line-h-0 { line-height: 0; }
.la { line-height: inherit }
.bg-dark hr,
.bg-primary hr{border-top-color: rgba(255,255,255,0.2);}

@media (max-width:991.98px) {
	.font-size-md-down-3 { font-size: 2rem; }
	.font-size-md-down-5 { font-size: 3rem; }
	.heroparallax {position: relative; bottom: inherit; right: inherit; width: 100%;}
	.cart-table .media {display: block;}
}
@media (max-width:767.98px) {
	.display-4 { font-size: calc(1.575rem + 3.9vw); }
	.parallaxie{background-position: center center !important;}
}
@media (max-width:991.98px) {
	.transform-md-rotate { transform: rotate(90deg); }
}
@media (max-width:767.98px) {
	.transform-md-rotate { background: #213262; transform: inherit; }
}

