@import url(https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
.m-0 {
	margin: 0 !important
}

.mt-0, .my-0 {
	margin-top: 0 !important
}

.mr-0, .mx-0 {
	margin-right: 0 !important
}

.mb-0, .my-0 {
	margin-bottom: 0 !important
}

.ml-0, .mx-0 {
	margin-left: 0 !important
}

.m-1 {
	margin: .25rem !important
}

.mt-1, .my-1 {
	margin-top: .25rem !important
}

.mr-1, .mx-1 {
	margin-right: .25rem !important
}

.mb-1, .my-1 {
	margin-bottom: .25rem !important
}

.ml-1, .mx-1 {
	margin-left: .25rem !important
}

.m-2 {
	margin: .5rem !important
}

.mt-2, .my-2 {
	margin-top: .5rem !important
}

.mr-2, .mx-2 {
	margin-right: .5rem !important
}

.mb-2, .my-2 {
	margin-bottom: .5rem !important
}

.ml-2, .mx-2 {
	margin-left: .5rem !important
}

.m-3 {
	margin: 1rem !important
}

.mt-3, .my-3 {
	margin-top: 1rem !important
}

.mr-3, .mx-3 {
	margin-right: 1rem !important
}

.mb-3, .my-3 {
	margin-bottom: 1rem !important
}

.ml-3, .mx-3 {
	margin-left: 1rem !important
}

.m-4 {
	margin: 1.5rem !important
}

.mt-4, .my-4 {
	margin-top: 1.5rem !important
}

.mr-4, .mx-4 {
	margin-right: 1.5rem !important
}

.mb-4, .my-4 {
	margin-bottom: 1.5rem !important
}

.ml-4, .mx-4 {
	margin-left: 1.5rem !important
}

.m-5 {
	margin: 2rem !important
}

.mt-5, .my-5 {
	margin-top: 2rem !important
}

.mr-5, .mx-5 {
	margin-right: 2rem !important
}

.mb-5, .my-5 {
	margin-bottom: 2rem !important
}

.ml-5, .mx-5 {
	margin-left: 2rem !important
}

.m-6 {
	margin: 2.5rem !important
}

.mt-6, .my-6 {
	margin-top: 2.5rem !important
}

.mr-6, .mx-6 {
	margin-right: 2.5rem !important
}

.mb-6, .my-6 {
	margin-bottom: 2.5rem !important
}

.ml-6, .mx-6 {
	margin-left: 2.5rem !important
}

.m-7 {
	margin: 3rem !important
}

.mt-7, .my-7 {
	margin-top: 3rem !important
}

.mr-7, .mx-7 {
	margin-right: 3rem !important
}

.mb-7, .my-7 {
	margin-bottom: 3rem !important
}

.ml-7, .mx-7 {
	margin-left: 3rem !important
}

.m-8 {
	margin: 3.5rem !important
}

.mt-8, .my-8 {
	margin-top: 3.5rem !important
}

.mr-8, .mx-8 {
	margin-right: 3.5rem !important
}

.mb-8, .my-8 {
	margin-bottom: 3.5rem !important
}

.ml-8, .mx-8 {
	margin-left: 3.5rem !important
}

.m-9 {
	margin: 4rem !important
}

.mt-9, .my-9 {
	margin-top: 4rem !important
}

.mr-9, .mx-9 {
	margin-right: 4rem !important
}

.mb-9, .my-9 {
	margin-bottom: 4rem !important
}

.ml-9, .mx-9 {
	margin-left: 4rem !important
}

.m-10 {
	margin: 4.5rem !important
}

.mt-10, .my-10 {
	margin-top: 4.5rem !important
}

.mr-10, .mx-10 {
	margin-right: 4.5rem !important
}

.mb-10, .my-10 {
	margin-bottom: 4.5rem !important
}

.ml-10, .mx-10 {
	margin-left: 4.5rem !important
}

.m-11 {
	margin: 5rem !important
}

.mt-11, .my-11 {
	margin-top: 5rem !important
}

.mr-11, .mx-11 {
	margin-right: 5rem !important
}

.mb-11, .my-11 {
	margin-bottom: 5rem !important
}

.ml-11, .mx-11 {
	margin-left: 5rem !important
}

.p-0 {
	padding: 0 !important
}

.pt-0, .py-0 {
	padding-top: 0 !important
}

.pr-0, .px-0 {
	padding-right: 0 !important
}

.pb-0, .py-0 {
	padding-bottom: 0 !important
}

.pl-0, .px-0 {
	padding-left: 0 !important
}

.p-1 {
	padding: .25rem !important
}

.pt-1, .py-1 {
	padding-top: .25rem !important
}

.pr-1, .px-1 {
	padding-right: .25rem !important
}

.pb-1, .py-1 {
	padding-bottom: .25rem !important
}

.pl-1, .px-1 {
	padding-left: .25rem !important
}

.p-2 {
	padding: .5rem !important
}

.pt-2, .py-2 {
	padding-top: .5rem !important
}

.pr-2, .px-2 {
	padding-right: .5rem !important
}

.pb-2, .py-2 {
	padding-bottom: .5rem !important
}

.pl-2, .px-2 {
	padding-left: .5rem !important
}

.p-3 {
	padding: 1rem !important
}

.pt-3, .py-3 {
	padding-top: 1rem !important
}

.pr-3, .px-3 {
	padding-right: 1rem !important
}

.pb-3, .py-3 {
	padding-bottom: 1rem !important
}

.pl-3, .px-3 {
	padding-left: 1rem !important
}

.p-4 {
	padding: 1.5rem !important
}

.pt-4, .py-4 {
	padding-top: 1.5rem !important
}

.pr-4, .px-4 {
	padding-right: 1.5rem !important
}

.pb-4, .py-4 {
	padding-bottom: 1.5rem !important
}

.pl-4, .px-4 {
	padding-left: 1.5rem !important
}

.p-5 {
	padding: 2rem !important
}

.pt-5, .py-5 {
	padding-top: 2rem !important
}

.pr-5, .px-5 {
	padding-right: 2rem !important
}

.pb-5, .py-5 {
	padding-bottom: 2rem !important
}

.pl-5, .px-5 {
	padding-left: 2rem !important
}

.p-6 {
	padding: 2.5rem !important
}

.pt-6, .py-6 {
	padding-top: 2.5rem !important
}

.pr-6, .px-6 {
	padding-right: 2.5rem !important
}

.pb-6, .py-6 {
	padding-bottom: 2.5rem !important
}

.pl-6, .px-6 {
	padding-left: 2.5rem !important
}

.p-7 {
	padding: 3rem !important
}

.pt-7, .py-7 {
	padding-top: 3rem !important
}

.pr-7, .px-7 {
	padding-right: 3rem !important
}

.pb-7, .py-7 {
	padding-bottom: 3rem !important
}

.pl-7, .px-7 {
	padding-left: 3rem !important
}

.p-8 {
	padding: 3.5rem !important
}

.pt-8, .py-8 {
	padding-top: 3.5rem !important
}

.pr-8, .px-8 {
	padding-right: 3.5rem !important
}

.pb-8, .py-8 {
	padding-bottom: 3.5rem !important
}

.pl-8, .px-8 {
	padding-left: 3.5rem !important
}

.p-9 {
	padding: 4rem !important
}

.pt-9, .py-9 {
	padding-top: 4rem !important
}

.pr-9, .px-9 {
	padding-right: 4rem !important
}

.pb-9, .py-9 {
	padding-bottom: 4rem !important
}

.pl-9, .px-9 {
	padding-left: 4rem !important
}

.p-10 {
	padding: 4.5rem !important
}

.pt-10, .py-10 {
	padding-top: 4.5rem !important
}

.pr-10, .px-10 {
	padding-right: 4.5rem !important
}

.pb-10, .py-10 {
	padding-bottom: 4.5rem !important
}

.pl-10, .px-10 {
	padding-left: 4.5rem !important
}

.p-11 {
	padding: 5rem !important
}

.pt-11, .py-11 {
	padding-top: 5rem !important
}

.pr-11, .px-11 {
	padding-right: 5rem !important
}

.pb-11, .py-11 {
	padding-bottom: 5rem !important
}

.pl-11, .px-11 {
	padding-left: 5rem !important
}

.m-n1 {
	margin: -.25rem !important
}

.mt-n1, .my-n1 {
	margin-top: -.25rem !important
}

.mr-n1, .mx-n1 {
	margin-right: -.25rem !important
}

.mb-n1, .my-n1 {
	margin-bottom: -.25rem !important
}

.ml-n1, .mx-n1 {
	margin-left: -.25rem !important
}

.m-n2 {
	margin: -.5rem !important
}

.mt-n2, .my-n2 {
	margin-top: -.5rem !important
}

.mr-n2, .mx-n2 {
	margin-right: -.5rem !important
}

.mb-n2, .my-n2 {
	margin-bottom: -.5rem !important
}

.ml-n2, .mx-n2 {
	margin-left: -.5rem !important
}

.m-n3 {
	margin: -1rem !important
}

.mt-n3, .my-n3 {
	margin-top: -1rem !important
}

.mr-n3, .mx-n3 {
	margin-right: -1rem !important
}

.mb-n3, .my-n3 {
	margin-bottom: -1rem !important
}

.ml-n3, .mx-n3 {
	margin-left: -1rem !important
}

.m-n4 {
	margin: -1.5rem !important
}

.mt-n4, .my-n4 {
	margin-top: -1.5rem !important
}

.mr-n4, .mx-n4 {
	margin-right: -1.5rem !important
}

.mb-n4, .my-n4 {
	margin-bottom: -1.5rem !important
}

.ml-n4, .mx-n4 {
	margin-left: -1.5rem !important
}

.m-n5 {
	margin: -2rem !important
}

.mt-n5, .my-n5 {
	margin-top: -2rem !important
}

.mr-n5, .mx-n5 {
	margin-right: -2rem !important
}

.mb-n5, .my-n5 {
	margin-bottom: -2rem !important
}

.ml-n5, .mx-n5 {
	margin-left: -2rem !important
}

.m-n6 {
	margin: -2.5rem !important
}

.mt-n6, .my-n6 {
	margin-top: -2.5rem !important
}

.mr-n6, .mx-n6 {
	margin-right: -2.5rem !important
}

.mb-n6, .my-n6 {
	margin-bottom: -2.5rem !important
}

.ml-n6, .mx-n6 {
	margin-left: -2.5rem !important
}

.m-n7 {
	margin: -3rem !important
}

.mt-n7, .my-n7 {
	margin-top: -3rem !important
}

.mr-n7, .mx-n7 {
	margin-right: -3rem !important
}

.mb-n7, .my-n7 {
	margin-bottom: -3rem !important
}

.ml-n7, .mx-n7 {
	margin-left: -3rem !important
}

.m-n8 {
	margin: -3.5rem !important
}

.mt-n8, .my-n8 {
	margin-top: -3.5rem !important
}

.mr-n8, .mx-n8 {
	margin-right: -3.5rem !important
}

.mb-n8, .my-n8 {
	margin-bottom: -3.5rem !important
}

.ml-n8, .mx-n8 {
	margin-left: -3.5rem !important
}

.m-n9 {
	margin: -4rem !important
}

.mt-n9, .my-n9 {
	margin-top: -4rem !important
}

.mr-n9, .mx-n9 {
	margin-right: -4rem !important
}

.mb-n9, .my-n9 {
	margin-bottom: -4rem !important
}

.ml-n9, .mx-n9 {
	margin-left: -4rem !important
}

.m-n10 {
	margin: -4.5rem !important
}

.mt-n10, .my-n10 {
	margin-top: -4.5rem !important
}

.mr-n10, .mx-n10 {
	margin-right: -4.5rem !important
}

.mb-n10, .my-n10 {
	margin-bottom: -4.5rem !important
}

.ml-n10, .mx-n10 {
	margin-left: -4.5rem !important
}

.m-n11 {
	margin: -5rem !important
}

.mt-n11, .my-n11 {
	margin-top: -5rem !important
}

.mr-n11, .mx-n11 {
	margin-right: -5rem !important
}

.mb-n11, .my-n11 {
	margin-bottom: -5rem !important
}

.ml-n11, .mx-n11 {
	margin-left: -5rem !important
}

.m-auto {
	margin: auto !important
}

.mt-auto, .my-auto {
	margin-top: auto !important
}

.mr-auto, .mx-auto {
	margin-right: auto !important
}

.mb-auto, .my-auto {
	margin-bottom: auto !important
}

.ml-auto, .mx-auto {
	margin-left: auto !important
}

@media (min-width:576px) {
	.m-sm-0 {
		margin: 0 !important
	}

	.mt-sm-0, .my-sm-0 {
		margin-top: 0 !important
	}

	.mr-sm-0, .mx-sm-0 {
		margin-right: 0 !important
	}

	.mb-sm-0, .my-sm-0 {
		margin-bottom: 0 !important
	}

	.ml-sm-0, .mx-sm-0 {
		margin-left: 0 !important
	}

	.m-sm-1 {
		margin: .25rem !important
	}

	.mt-sm-1, .my-sm-1 {
		margin-top: .25rem !important
	}

	.mr-sm-1, .mx-sm-1 {
		margin-right: .25rem !important
	}

	.mb-sm-1, .my-sm-1 {
		margin-bottom: .25rem !important
	}

	.ml-sm-1, .mx-sm-1 {
		margin-left: .25rem !important
	}

	.m-sm-2 {
		margin: .5rem !important
	}

	.mt-sm-2, .my-sm-2 {
		margin-top: .5rem !important
	}

	.mr-sm-2, .mx-sm-2 {
		margin-right: .5rem !important
	}

	.mb-sm-2, .my-sm-2 {
		margin-bottom: .5rem !important
	}

	.ml-sm-2, .mx-sm-2 {
		margin-left: .5rem !important
	}

	.m-sm-3 {
		margin: 1rem !important
	}

	.mt-sm-3, .my-sm-3 {
		margin-top: 1rem !important
	}

	.mr-sm-3, .mx-sm-3 {
		margin-right: 1rem !important
	}

	.mb-sm-3, .my-sm-3 {
		margin-bottom: 1rem !important
	}

	.ml-sm-3, .mx-sm-3 {
		margin-left: 1rem !important
	}

	.m-sm-4 {
		margin: 1.5rem !important
	}

	.mt-sm-4, .my-sm-4 {
		margin-top: 1.5rem !important
	}

	.mr-sm-4, .mx-sm-4 {
		margin-right: 1.5rem !important
	}

	.mb-sm-4, .my-sm-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-sm-4, .mx-sm-4 {
		margin-left: 1.5rem !important
	}

	.m-sm-5 {
		margin: 2rem !important
	}

	.mt-sm-5, .my-sm-5 {
		margin-top: 2rem !important
	}

	.mr-sm-5, .mx-sm-5 {
		margin-right: 2rem !important
	}

	.mb-sm-5, .my-sm-5 {
		margin-bottom: 2rem !important
	}

	.ml-sm-5, .mx-sm-5 {
		margin-left: 2rem !important
	}

	.m-sm-6 {
		margin: 2.5rem !important
	}

	.mt-sm-6, .my-sm-6 {
		margin-top: 2.5rem !important
	}

	.mr-sm-6, .mx-sm-6 {
		margin-right: 2.5rem !important
	}

	.mb-sm-6, .my-sm-6 {
		margin-bottom: 2.5rem !important
	}

	.ml-sm-6, .mx-sm-6 {
		margin-left: 2.5rem !important
	}

	.m-sm-7 {
		margin: 3rem !important
	}

	.mt-sm-7, .my-sm-7 {
		margin-top: 3rem !important
	}

	.mr-sm-7, .mx-sm-7 {
		margin-right: 3rem !important
	}

	.mb-sm-7, .my-sm-7 {
		margin-bottom: 3rem !important
	}

	.ml-sm-7, .mx-sm-7 {
		margin-left: 3rem !important
	}

	.m-sm-8 {
		margin: 3.5rem !important
	}

	.mt-sm-8, .my-sm-8 {
		margin-top: 3.5rem !important
	}

	.mr-sm-8, .mx-sm-8 {
		margin-right: 3.5rem !important
	}

	.mb-sm-8, .my-sm-8 {
		margin-bottom: 3.5rem !important
	}

	.ml-sm-8, .mx-sm-8 {
		margin-left: 3.5rem !important
	}

	.m-sm-9 {
		margin: 4rem !important
	}

	.mt-sm-9, .my-sm-9 {
		margin-top: 4rem !important
	}

	.mr-sm-9, .mx-sm-9 {
		margin-right: 4rem !important
	}

	.mb-sm-9, .my-sm-9 {
		margin-bottom: 4rem !important
	}

	.ml-sm-9, .mx-sm-9 {
		margin-left: 4rem !important
	}

	.m-sm-10 {
		margin: 4.5rem !important
	}

	.mt-sm-10, .my-sm-10 {
		margin-top: 4.5rem !important
	}

	.mr-sm-10, .mx-sm-10 {
		margin-right: 4.5rem !important
	}

	.mb-sm-10, .my-sm-10 {
		margin-bottom: 4.5rem !important
	}

	.ml-sm-10, .mx-sm-10 {
		margin-left: 4.5rem !important
	}

	.m-sm-11 {
		margin: 5rem !important
	}

	.mt-sm-11, .my-sm-11 {
		margin-top: 5rem !important
	}

	.mr-sm-11, .mx-sm-11 {
		margin-right: 5rem !important
	}

	.mb-sm-11, .my-sm-11 {
		margin-bottom: 5rem !important
	}

	.ml-sm-11, .mx-sm-11 {
		margin-left: 5rem !important
	}

	.p-sm-0 {
		padding: 0 !important
	}

	.pt-sm-0, .py-sm-0 {
		padding-top: 0 !important
	}

	.pr-sm-0, .px-sm-0 {
		padding-right: 0 !important
	}

	.pb-sm-0, .py-sm-0 {
		padding-bottom: 0 !important
	}

	.pl-sm-0, .px-sm-0 {
		padding-left: 0 !important
	}

	.p-sm-1 {
		padding: .25rem !important
	}

	.pt-sm-1, .py-sm-1 {
		padding-top: .25rem !important
	}

	.pr-sm-1, .px-sm-1 {
		padding-right: .25rem !important
	}

	.pb-sm-1, .py-sm-1 {
		padding-bottom: .25rem !important
	}

	.pl-sm-1, .px-sm-1 {
		padding-left: .25rem !important
	}

	.p-sm-2 {
		padding: .5rem !important
	}

	.pt-sm-2, .py-sm-2 {
		padding-top: .5rem !important
	}

	.pr-sm-2, .px-sm-2 {
		padding-right: .5rem !important
	}

	.pb-sm-2, .py-sm-2 {
		padding-bottom: .5rem !important
	}

	.pl-sm-2, .px-sm-2 {
		padding-left: .5rem !important
	}

	.p-sm-3 {
		padding: 1rem !important
	}

	.pt-sm-3, .py-sm-3 {
		padding-top: 1rem !important
	}

	.pr-sm-3, .px-sm-3 {
		padding-right: 1rem !important
	}

	.pb-sm-3, .py-sm-3 {
		padding-bottom: 1rem !important
	}

	.pl-sm-3, .px-sm-3 {
		padding-left: 1rem !important
	}

	.p-sm-4 {
		padding: 1.5rem !important
	}

	.pt-sm-4, .py-sm-4 {
		padding-top: 1.5rem !important
	}

	.pr-sm-4, .px-sm-4 {
		padding-right: 1.5rem !important
	}

	.pb-sm-4, .py-sm-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-sm-4, .px-sm-4 {
		padding-left: 1.5rem !important
	}

	.p-sm-5 {
		padding: 2rem !important
	}

	.pt-sm-5, .py-sm-5 {
		padding-top: 2rem !important
	}

	.pr-sm-5, .px-sm-5 {
		padding-right: 2rem !important
	}

	.pb-sm-5, .py-sm-5 {
		padding-bottom: 2rem !important
	}

	.pl-sm-5, .px-sm-5 {
		padding-left: 2rem !important
	}

	.p-sm-6 {
		padding: 2.5rem !important
	}

	.pt-sm-6, .py-sm-6 {
		padding-top: 2.5rem !important
	}

	.pr-sm-6, .px-sm-6 {
		padding-right: 2.5rem !important
	}

	.pb-sm-6, .py-sm-6 {
		padding-bottom: 2.5rem !important
	}

	.pl-sm-6, .px-sm-6 {
		padding-left: 2.5rem !important
	}

	.p-sm-7 {
		padding: 3rem !important
	}

	.pt-sm-7, .py-sm-7 {
		padding-top: 3rem !important
	}

	.pr-sm-7, .px-sm-7 {
		padding-right: 3rem !important
	}

	.pb-sm-7, .py-sm-7 {
		padding-bottom: 3rem !important
	}

	.pl-sm-7, .px-sm-7 {
		padding-left: 3rem !important
	}

	.p-sm-8 {
		padding: 3.5rem !important
	}

	.pt-sm-8, .py-sm-8 {
		padding-top: 3.5rem !important
	}

	.pr-sm-8, .px-sm-8 {
		padding-right: 3.5rem !important
	}

	.pb-sm-8, .py-sm-8 {
		padding-bottom: 3.5rem !important
	}

	.pl-sm-8, .px-sm-8 {
		padding-left: 3.5rem !important
	}

	.p-sm-9 {
		padding: 4rem !important
	}

	.pt-sm-9, .py-sm-9 {
		padding-top: 4rem !important
	}

	.pr-sm-9, .px-sm-9 {
		padding-right: 4rem !important
	}

	.pb-sm-9, .py-sm-9 {
		padding-bottom: 4rem !important
	}

	.pl-sm-9, .px-sm-9 {
		padding-left: 4rem !important
	}

	.p-sm-10 {
		padding: 4.5rem !important
	}

	.pt-sm-10, .py-sm-10 {
		padding-top: 4.5rem !important
	}

	.pr-sm-10, .px-sm-10 {
		padding-right: 4.5rem !important
	}

	.pb-sm-10, .py-sm-10 {
		padding-bottom: 4.5rem !important
	}

	.pl-sm-10, .px-sm-10 {
		padding-left: 4.5rem !important
	}

	.p-sm-11 {
		padding: 5rem !important
	}

	.pt-sm-11, .py-sm-11 {
		padding-top: 5rem !important
	}

	.pr-sm-11, .px-sm-11 {
		padding-right: 5rem !important
	}

	.pb-sm-11, .py-sm-11 {
		padding-bottom: 5rem !important
	}

	.pl-sm-11, .px-sm-11 {
		padding-left: 5rem !important
	}

	.m-sm-n1 {
		margin: -.25rem !important
	}

	.mt-sm-n1, .my-sm-n1 {
		margin-top: -.25rem !important
	}

	.mr-sm-n1, .mx-sm-n1 {
		margin-right: -.25rem !important
	}

	.mb-sm-n1, .my-sm-n1 {
		margin-bottom: -.25rem !important
	}

	.ml-sm-n1, .mx-sm-n1 {
		margin-left: -.25rem !important
	}

	.m-sm-n2 {
		margin: -.5rem !important
	}

	.mt-sm-n2, .my-sm-n2 {
		margin-top: -.5rem !important
	}

	.mr-sm-n2, .mx-sm-n2 {
		margin-right: -.5rem !important
	}

	.mb-sm-n2, .my-sm-n2 {
		margin-bottom: -.5rem !important
	}

	.ml-sm-n2, .mx-sm-n2 {
		margin-left: -.5rem !important
	}

	.m-sm-n3 {
		margin: -1rem !important
	}

	.mt-sm-n3, .my-sm-n3 {
		margin-top: -1rem !important
	}

	.mr-sm-n3, .mx-sm-n3 {
		margin-right: -1rem !important
	}

	.mb-sm-n3, .my-sm-n3 {
		margin-bottom: -1rem !important
	}

	.ml-sm-n3, .mx-sm-n3 {
		margin-left: -1rem !important
	}

	.m-sm-n4 {
		margin: -1.5rem !important
	}

	.mt-sm-n4, .my-sm-n4 {
		margin-top: -1.5rem !important
	}

	.mr-sm-n4, .mx-sm-n4 {
		margin-right: -1.5rem !important
	}

	.mb-sm-n4, .my-sm-n4 {
		margin-bottom: -1.5rem !important
	}

	.ml-sm-n4, .mx-sm-n4 {
		margin-left: -1.5rem !important
	}

	.m-sm-n5 {
		margin: -2rem !important
	}

	.mt-sm-n5, .my-sm-n5 {
		margin-top: -2rem !important
	}

	.mr-sm-n5, .mx-sm-n5 {
		margin-right: -2rem !important
	}

	.mb-sm-n5, .my-sm-n5 {
		margin-bottom: -2rem !important
	}

	.ml-sm-n5, .mx-sm-n5 {
		margin-left: -2rem !important
	}

	.m-sm-n6 {
		margin: -2.5rem !important
	}

	.mt-sm-n6, .my-sm-n6 {
		margin-top: -2.5rem !important
	}

	.mr-sm-n6, .mx-sm-n6 {
		margin-right: -2.5rem !important
	}

	.mb-sm-n6, .my-sm-n6 {
		margin-bottom: -2.5rem !important
	}

	.ml-sm-n6, .mx-sm-n6 {
		margin-left: -2.5rem !important
	}

	.m-sm-n7 {
		margin: -3rem !important
	}

	.mt-sm-n7, .my-sm-n7 {
		margin-top: -3rem !important
	}

	.mr-sm-n7, .mx-sm-n7 {
		margin-right: -3rem !important
	}

	.mb-sm-n7, .my-sm-n7 {
		margin-bottom: -3rem !important
	}

	.ml-sm-n7, .mx-sm-n7 {
		margin-left: -3rem !important
	}

	.m-sm-n8 {
		margin: -3.5rem !important
	}

	.mt-sm-n8, .my-sm-n8 {
		margin-top: -3.5rem !important
	}

	.mr-sm-n8, .mx-sm-n8 {
		margin-right: -3.5rem !important
	}

	.mb-sm-n8, .my-sm-n8 {
		margin-bottom: -3.5rem !important
	}

	.ml-sm-n8, .mx-sm-n8 {
		margin-left: -3.5rem !important
	}

	.m-sm-n9 {
		margin: -4rem !important
	}

	.mt-sm-n9, .my-sm-n9 {
		margin-top: -4rem !important
	}

	.mr-sm-n9, .mx-sm-n9 {
		margin-right: -4rem !important
	}

	.mb-sm-n9, .my-sm-n9 {
		margin-bottom: -4rem !important
	}

	.ml-sm-n9, .mx-sm-n9 {
		margin-left: -4rem !important
	}

	.m-sm-n10 {
		margin: -4.5rem !important
	}

	.mt-sm-n10, .my-sm-n10 {
		margin-top: -4.5rem !important
	}

	.mr-sm-n10, .mx-sm-n10 {
		margin-right: -4.5rem !important
	}

	.mb-sm-n10, .my-sm-n10 {
		margin-bottom: -4.5rem !important
	}

	.ml-sm-n10, .mx-sm-n10 {
		margin-left: -4.5rem !important
	}

	.m-sm-n11 {
		margin: -5rem !important
	}

	.mt-sm-n11, .my-sm-n11 {
		margin-top: -5rem !important
	}

	.mr-sm-n11, .mx-sm-n11 {
		margin-right: -5rem !important
	}

	.mb-sm-n11, .my-sm-n11 {
		margin-bottom: -5rem !important
	}

	.ml-sm-n11, .mx-sm-n11 {
		margin-left: -5rem !important
	}

	.m-sm-auto {
		margin: auto !important
	}

	.mt-sm-auto, .my-sm-auto {
		margin-top: auto !important
	}

	.mr-sm-auto, .mx-sm-auto {
		margin-right: auto !important
	}

	.mb-sm-auto, .my-sm-auto {
		margin-bottom: auto !important
	}

	.ml-sm-auto, .mx-sm-auto {
		margin-left: auto !important
	}

}

@media (min-width:768px) {
	.m-md-0 {
		margin: 0 !important
	}

	.mt-md-0, .my-md-0 {
		margin-top: 0 !important
	}

	.mr-md-0, .mx-md-0 {
		margin-right: 0 !important
	}

	.mb-md-0, .my-md-0 {
		margin-bottom: 0 !important
	}

	.ml-md-0, .mx-md-0 {
		margin-left: 0 !important
	}

	.m-md-1 {
		margin: .25rem !important
	}

	.mt-md-1, .my-md-1 {
		margin-top: .25rem !important
	}

	.mr-md-1, .mx-md-1 {
		margin-right: .25rem !important
	}

	.mb-md-1, .my-md-1 {
		margin-bottom: .25rem !important
	}

	.ml-md-1, .mx-md-1 {
		margin-left: .25rem !important
	}

	.m-md-2 {
		margin: .5rem !important
	}

	.mt-md-2, .my-md-2 {
		margin-top: .5rem !important
	}

	.mr-md-2, .mx-md-2 {
		margin-right: .5rem !important
	}

	.mb-md-2, .my-md-2 {
		margin-bottom: .5rem !important
	}

	.ml-md-2, .mx-md-2 {
		margin-left: .5rem !important
	}

	.m-md-3 {
		margin: 1rem !important
	}

	.mt-md-3, .my-md-3 {
		margin-top: 1rem !important
	}

	.mr-md-3, .mx-md-3 {
		margin-right: 1rem !important
	}

	.mb-md-3, .my-md-3 {
		margin-bottom: 1rem !important
	}

	.ml-md-3, .mx-md-3 {
		margin-left: 1rem !important
	}

	.m-md-4 {
		margin: 1.5rem !important
	}

	.mt-md-4, .my-md-4 {
		margin-top: 1.5rem !important
	}

	.mr-md-4, .mx-md-4 {
		margin-right: 1.5rem !important
	}

	.mb-md-4, .my-md-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-md-4, .mx-md-4 {
		margin-left: 1.5rem !important
	}

	.m-md-5 {
		margin: 2rem !important
	}

	.mt-md-5, .my-md-5 {
		margin-top: 2rem !important
	}

	.mr-md-5, .mx-md-5 {
		margin-right: 2rem !important
	}

	.mb-md-5, .my-md-5 {
		margin-bottom: 2rem !important
	}

	.ml-md-5, .mx-md-5 {
		margin-left: 2rem !important
	}

	.m-md-6 {
		margin: 2.5rem !important
	}

	.mt-md-6, .my-md-6 {
		margin-top: 2.5rem !important
	}

	.mr-md-6, .mx-md-6 {
		margin-right: 2.5rem !important
	}

	.mb-md-6, .my-md-6 {
		margin-bottom: 2.5rem !important
	}

	.ml-md-6, .mx-md-6 {
		margin-left: 2.5rem !important
	}

	.m-md-7 {
		margin: 3rem !important
	}

	.mt-md-7, .my-md-7 {
		margin-top: 3rem !important
	}

	.mr-md-7, .mx-md-7 {
		margin-right: 3rem !important
	}

	.mb-md-7, .my-md-7 {
		margin-bottom: 3rem !important
	}

	.ml-md-7, .mx-md-7 {
		margin-left: 3rem !important
	}

	.m-md-8 {
		margin: 3.5rem !important
	}

	.mt-md-8, .my-md-8 {
		margin-top: 3.5rem !important
	}

	.mr-md-8, .mx-md-8 {
		margin-right: 3.5rem !important
	}

	.mb-md-8, .my-md-8 {
		margin-bottom: 3.5rem !important
	}

	.ml-md-8, .mx-md-8 {
		margin-left: 3.5rem !important
	}

	.m-md-9 {
		margin: 4rem !important
	}

	.mt-md-9, .my-md-9 {
		margin-top: 4rem !important
	}

	.mr-md-9, .mx-md-9 {
		margin-right: 4rem !important
	}

	.mb-md-9, .my-md-9 {
		margin-bottom: 4rem !important
	}

	.ml-md-9, .mx-md-9 {
		margin-left: 4rem !important
	}

	.m-md-10 {
		margin: 4.5rem !important
	}

	.mt-md-10, .my-md-10 {
		margin-top: 4.5rem !important
	}

	.mr-md-10, .mx-md-10 {
		margin-right: 4.5rem !important
	}

	.mb-md-10, .my-md-10 {
		margin-bottom: 4.5rem !important
	}

	.ml-md-10, .mx-md-10 {
		margin-left: 4.5rem !important
	}

	.m-md-11 {
		margin: 5rem !important
	}

	.mt-md-11, .my-md-11 {
		margin-top: 5rem !important
	}

	.mr-md-11, .mx-md-11 {
		margin-right: 5rem !important
	}

	.mb-md-11, .my-md-11 {
		margin-bottom: 5rem !important
	}

	.ml-md-11, .mx-md-11 {
		margin-left: 5rem !important
	}

	.p-md-0 {
		padding: 0 !important
	}

	.pt-md-0, .py-md-0 {
		padding-top: 0 !important
	}

	.pr-md-0, .px-md-0 {
		padding-right: 0 !important
	}

	.pb-md-0, .py-md-0 {
		padding-bottom: 0 !important
	}

	.pl-md-0, .px-md-0 {
		padding-left: 0 !important
	}

	.p-md-1 {
		padding: .25rem !important
	}

	.pt-md-1, .py-md-1 {
		padding-top: .25rem !important
	}

	.pr-md-1, .px-md-1 {
		padding-right: .25rem !important
	}

	.pb-md-1, .py-md-1 {
		padding-bottom: .25rem !important
	}

	.pl-md-1, .px-md-1 {
		padding-left: .25rem !important
	}

	.p-md-2 {
		padding: .5rem !important
	}

	.pt-md-2, .py-md-2 {
		padding-top: .5rem !important
	}

	.pr-md-2, .px-md-2 {
		padding-right: .5rem !important
	}

	.pb-md-2, .py-md-2 {
		padding-bottom: .5rem !important
	}

	.pl-md-2, .px-md-2 {
		padding-left: .5rem !important
	}

	.p-md-3 {
		padding: 1rem !important
	}

	.pt-md-3, .py-md-3 {
		padding-top: 1rem !important
	}

	.pr-md-3, .px-md-3 {
		padding-right: 1rem !important
	}

	.pb-md-3, .py-md-3 {
		padding-bottom: 1rem !important
	}

	.pl-md-3, .px-md-3 {
		padding-left: 1rem !important
	}

	.p-md-4 {
		padding: 1.5rem !important
	}

	.pt-md-4, .py-md-4 {
		padding-top: 1.5rem !important
	}

	.pr-md-4, .px-md-4 {
		padding-right: 1.5rem !important
	}

	.pb-md-4, .py-md-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-md-4, .px-md-4 {
		padding-left: 1.5rem !important
	}

	.p-md-5 {
		padding: 2rem !important
	}

	.pt-md-5, .py-md-5 {
		padding-top: 2rem !important
	}

	.pr-md-5, .px-md-5 {
		padding-right: 2rem !important
	}

	.pb-md-5, .py-md-5 {
		padding-bottom: 2rem !important
	}

	.pl-md-5, .px-md-5 {
		padding-left: 2rem !important
	}

	.p-md-6 {
		padding: 2.5rem !important
	}

	.pt-md-6, .py-md-6 {
		padding-top: 2.5rem !important
	}

	.pr-md-6, .px-md-6 {
		padding-right: 2.5rem !important
	}

	.pb-md-6, .py-md-6 {
		padding-bottom: 2.5rem !important
	}

	.pl-md-6, .px-md-6 {
		padding-left: 2.5rem !important
	}

	.p-md-7 {
		padding: 3rem !important
	}

	.pt-md-7, .py-md-7 {
		padding-top: 3rem !important
	}

	.pr-md-7, .px-md-7 {
		padding-right: 3rem !important
	}

	.pb-md-7, .py-md-7 {
		padding-bottom: 3rem !important
	}

	.pl-md-7, .px-md-7 {
		padding-left: 3rem !important
	}

	.p-md-8 {
		padding: 3.5rem !important
	}

	.pt-md-8, .py-md-8 {
		padding-top: 3.5rem !important
	}

	.pr-md-8, .px-md-8 {
		padding-right: 3.5rem !important
	}

	.pb-md-8, .py-md-8 {
		padding-bottom: 3.5rem !important
	}

	.pl-md-8, .px-md-8 {
		padding-left: 3.5rem !important
	}

	.p-md-9 {
		padding: 4rem !important
	}

	.pt-md-9, .py-md-9 {
		padding-top: 4rem !important
	}

	.pr-md-9, .px-md-9 {
		padding-right: 4rem !important
	}

	.pb-md-9, .py-md-9 {
		padding-bottom: 4rem !important
	}

	.pl-md-9, .px-md-9 {
		padding-left: 4rem !important
	}

	.p-md-10 {
		padding: 4.5rem !important
	}

	.pt-md-10, .py-md-10 {
		padding-top: 4.5rem !important
	}

	.pr-md-10, .px-md-10 {
		padding-right: 4.5rem !important
	}

	.pb-md-10, .py-md-10 {
		padding-bottom: 4.5rem !important
	}

	.pl-md-10, .px-md-10 {
		padding-left: 4.5rem !important
	}

	.p-md-11 {
		padding: 5rem !important
	}

	.pt-md-11, .py-md-11 {
		padding-top: 5rem !important
	}

	.pr-md-11, .px-md-11 {
		padding-right: 5rem !important
	}

	.pb-md-11, .py-md-11 {
		padding-bottom: 5rem !important
	}

	.pl-md-11, .px-md-11 {
		padding-left: 5rem !important
	}

	.m-md-n1 {
		margin: -.25rem !important
	}

	.mt-md-n1, .my-md-n1 {
		margin-top: -.25rem !important
	}

	.mr-md-n1, .mx-md-n1 {
		margin-right: -.25rem !important
	}

	.mb-md-n1, .my-md-n1 {
		margin-bottom: -.25rem !important
	}

	.ml-md-n1, .mx-md-n1 {
		margin-left: -.25rem !important
	}

	.m-md-n2 {
		margin: -.5rem !important
	}

	.mt-md-n2, .my-md-n2 {
		margin-top: -.5rem !important
	}

	.mr-md-n2, .mx-md-n2 {
		margin-right: -.5rem !important
	}

	.mb-md-n2, .my-md-n2 {
		margin-bottom: -.5rem !important
	}

	.ml-md-n2, .mx-md-n2 {
		margin-left: -.5rem !important
	}

	.m-md-n3 {
		margin: -1rem !important
	}

	.mt-md-n3, .my-md-n3 {
		margin-top: -1rem !important
	}

	.mr-md-n3, .mx-md-n3 {
		margin-right: -1rem !important
	}

	.mb-md-n3, .my-md-n3 {
		margin-bottom: -1rem !important
	}

	.ml-md-n3, .mx-md-n3 {
		margin-left: -1rem !important
	}

	.m-md-n4 {
		margin: -1.5rem !important
	}

	.mt-md-n4, .my-md-n4 {
		margin-top: -1.5rem !important
	}

	.mr-md-n4, .mx-md-n4 {
		margin-right: -1.5rem !important
	}

	.mb-md-n4, .my-md-n4 {
		margin-bottom: -1.5rem !important
	}

	.ml-md-n4, .mx-md-n4 {
		margin-left: -1.5rem !important
	}

	.m-md-n5 {
		margin: -2rem !important
	}

	.mt-md-n5, .my-md-n5 {
		margin-top: -2rem !important
	}

	.mr-md-n5, .mx-md-n5 {
		margin-right: -2rem !important
	}

	.mb-md-n5, .my-md-n5 {
		margin-bottom: -2rem !important
	}

	.ml-md-n5, .mx-md-n5 {
		margin-left: -2rem !important
	}

	.m-md-n6 {
		margin: -2.5rem !important
	}

	.mt-md-n6, .my-md-n6 {
		margin-top: -2.5rem !important
	}

	.mr-md-n6, .mx-md-n6 {
		margin-right: -2.5rem !important
	}

	.mb-md-n6, .my-md-n6 {
		margin-bottom: -2.5rem !important
	}

	.ml-md-n6, .mx-md-n6 {
		margin-left: -2.5rem !important
	}

	.m-md-n7 {
		margin: -3rem !important
	}

	.mt-md-n7, .my-md-n7 {
		margin-top: -3rem !important
	}

	.mr-md-n7, .mx-md-n7 {
		margin-right: -3rem !important
	}

	.mb-md-n7, .my-md-n7 {
		margin-bottom: -3rem !important
	}

	.ml-md-n7, .mx-md-n7 {
		margin-left: -3rem !important
	}

	.m-md-n8 {
		margin: -3.5rem !important
	}

	.mt-md-n8, .my-md-n8 {
		margin-top: -3.5rem !important
	}

	.mr-md-n8, .mx-md-n8 {
		margin-right: -3.5rem !important
	}

	.mb-md-n8, .my-md-n8 {
		margin-bottom: -3.5rem !important
	}

	.ml-md-n8, .mx-md-n8 {
		margin-left: -3.5rem !important
	}

	.m-md-n9 {
		margin: -4rem !important
	}

	.mt-md-n9, .my-md-n9 {
		margin-top: -4rem !important
	}

	.mr-md-n9, .mx-md-n9 {
		margin-right: -4rem !important
	}

	.mb-md-n9, .my-md-n9 {
		margin-bottom: -4rem !important
	}

	.ml-md-n9, .mx-md-n9 {
		margin-left: -4rem !important
	}

	.m-md-n10 {
		margin: -4.5rem !important
	}

	.mt-md-n10, .my-md-n10 {
		margin-top: -4.5rem !important
	}

	.mr-md-n10, .mx-md-n10 {
		margin-right: -4.5rem !important
	}

	.mb-md-n10, .my-md-n10 {
		margin-bottom: -4.5rem !important
	}

	.ml-md-n10, .mx-md-n10 {
		margin-left: -4.5rem !important
	}

	.m-md-n11 {
		margin: -5rem !important
	}

	.mt-md-n11, .my-md-n11 {
		margin-top: -5rem !important
	}

	.mr-md-n11, .mx-md-n11 {
		margin-right: -5rem !important
	}

	.mb-md-n11, .my-md-n11 {
		margin-bottom: -5rem !important
	}

	.ml-md-n11, .mx-md-n11 {
		margin-left: -5rem !important
	}

	.m-md-auto {
		margin: auto !important
	}

	.mt-md-auto, .my-md-auto {
		margin-top: auto !important
	}

	.mr-md-auto, .mx-md-auto {
		margin-right: auto !important
	}

	.mb-md-auto, .my-md-auto {
		margin-bottom: auto !important
	}

	.ml-md-auto, .mx-md-auto {
		margin-left: auto !important
	}

}

@media (min-width:992px) {
	.m-lg-0 {
		margin: 0 !important
	}

	.mt-lg-0, .my-lg-0 {
		margin-top: 0 !important
	}

	.mr-lg-0, .mx-lg-0 {
		margin-right: 0 !important
	}

	.mb-lg-0, .my-lg-0 {
		margin-bottom: 0 !important
	}

	.ml-lg-0, .mx-lg-0 {
		margin-left: 0 !important
	}

	.m-lg-1 {
		margin: .25rem !important
	}

	.mt-lg-1, .my-lg-1 {
		margin-top: .25rem !important
	}

	.mr-lg-1, .mx-lg-1 {
		margin-right: .25rem !important
	}

	.mb-lg-1, .my-lg-1 {
		margin-bottom: .25rem !important
	}

	.ml-lg-1, .mx-lg-1 {
		margin-left: .25rem !important
	}

	.m-lg-2 {
		margin: .5rem !important
	}

	.mt-lg-2, .my-lg-2 {
		margin-top: .5rem !important
	}

	.mr-lg-2, .mx-lg-2 {
		margin-right: .5rem !important
	}

	.mb-lg-2, .my-lg-2 {
		margin-bottom: .5rem !important
	}

	.ml-lg-2, .mx-lg-2 {
		margin-left: .5rem !important
	}

	.m-lg-3 {
		margin: 1rem !important
	}

	.mt-lg-3, .my-lg-3 {
		margin-top: 1rem !important
	}

	.mr-lg-3, .mx-lg-3 {
		margin-right: 1rem !important
	}

	.mb-lg-3, .my-lg-3 {
		margin-bottom: 1rem !important
	}

	.ml-lg-3, .mx-lg-3 {
		margin-left: 1rem !important
	}

	.m-lg-4 {
		margin: 1.5rem !important
	}

	.mt-lg-4, .my-lg-4 {
		margin-top: 1.5rem !important
	}

	.mr-lg-4, .mx-lg-4 {
		margin-right: 1.5rem !important
	}

	.mb-lg-4, .my-lg-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-lg-4, .mx-lg-4 {
		margin-left: 1.5rem !important
	}

	.m-lg-5 {
		margin: 2rem !important
	}

	.mt-lg-5, .my-lg-5 {
		margin-top: 2rem !important
	}

	.mr-lg-5, .mx-lg-5 {
		margin-right: 2rem !important
	}

	.mb-lg-5, .my-lg-5 {
		margin-bottom: 2rem !important
	}

	.ml-lg-5, .mx-lg-5 {
		margin-left: 2rem !important
	}

	.m-lg-6 {
		margin: 2.5rem !important
	}

	.mt-lg-6, .my-lg-6 {
		margin-top: 2.5rem !important
	}

	.mr-lg-6, .mx-lg-6 {
		margin-right: 2.5rem !important
	}

	.mb-lg-6, .my-lg-6 {
		margin-bottom: 2.5rem !important
	}

	.ml-lg-6, .mx-lg-6 {
		margin-left: 2.5rem !important
	}

	.m-lg-7 {
		margin: 3rem !important
	}

	.mt-lg-7, .my-lg-7 {
		margin-top: 3rem !important
	}

	.mr-lg-7, .mx-lg-7 {
		margin-right: 3rem !important
	}

	.mb-lg-7, .my-lg-7 {
		margin-bottom: 3rem !important
	}

	.ml-lg-7, .mx-lg-7 {
		margin-left: 3rem !important
	}

	.m-lg-8 {
		margin: 3.5rem !important
	}

	.mt-lg-8, .my-lg-8 {
		margin-top: 3.5rem !important
	}

	.mr-lg-8, .mx-lg-8 {
		margin-right: 3.5rem !important
	}

	.mb-lg-8, .my-lg-8 {
		margin-bottom: 3.5rem !important
	}

	.ml-lg-8, .mx-lg-8 {
		margin-left: 3.5rem !important
	}

	.m-lg-9 {
		margin: 4rem !important
	}

	.mt-lg-9, .my-lg-9 {
		margin-top: 4rem !important
	}

	.mr-lg-9, .mx-lg-9 {
		margin-right: 4rem !important
	}

	.mb-lg-9, .my-lg-9 {
		margin-bottom: 4rem !important
	}

	.ml-lg-9, .mx-lg-9 {
		margin-left: 4rem !important
	}

	.m-lg-10 {
		margin: 4.5rem !important
	}

	.mt-lg-10, .my-lg-10 {
		margin-top: 4.5rem !important
	}

	.mr-lg-10, .mx-lg-10 {
		margin-right: 4.5rem !important
	}

	.mb-lg-10, .my-lg-10 {
		margin-bottom: 4.5rem !important
	}

	.ml-lg-10, .mx-lg-10 {
		margin-left: 4.5rem !important
	}

	.m-lg-11 {
		margin: 5rem !important
	}

	.mt-lg-11, .my-lg-11 {
		margin-top: 5rem !important
	}

	.mr-lg-11, .mx-lg-11 {
		margin-right: 5rem !important
	}

	.mb-lg-11, .my-lg-11 {
		margin-bottom: 5rem !important
	}

	.ml-lg-11, .mx-lg-11 {
		margin-left: 5rem !important
	}

	.p-lg-0 {
		padding: 0 !important
	}

	.pt-lg-0, .py-lg-0 {
		padding-top: 0 !important
	}

	.pr-lg-0, .px-lg-0 {
		padding-right: 0 !important
	}

	.pb-lg-0, .py-lg-0 {
		padding-bottom: 0 !important
	}

	.pl-lg-0, .px-lg-0 {
		padding-left: 0 !important
	}

	.p-lg-1 {
		padding: .25rem !important
	}

	.pt-lg-1, .py-lg-1 {
		padding-top: .25rem !important
	}

	.pr-lg-1, .px-lg-1 {
		padding-right: .25rem !important
	}

	.pb-lg-1, .py-lg-1 {
		padding-bottom: .25rem !important
	}

	.pl-lg-1, .px-lg-1 {
		padding-left: .25rem !important
	}

	.p-lg-2 {
		padding: .5rem !important
	}

	.pt-lg-2, .py-lg-2 {
		padding-top: .5rem !important
	}

	.pr-lg-2, .px-lg-2 {
		padding-right: .5rem !important
	}

	.pb-lg-2, .py-lg-2 {
		padding-bottom: .5rem !important
	}

	.pl-lg-2, .px-lg-2 {
		padding-left: .5rem !important
	}

	.p-lg-3 {
		padding: 1rem !important
	}

	.pt-lg-3, .py-lg-3 {
		padding-top: 1rem !important
	}

	.pr-lg-3, .px-lg-3 {
		padding-right: 1rem !important
	}

	.pb-lg-3, .py-lg-3 {
		padding-bottom: 1rem !important
	}

	.pl-lg-3, .px-lg-3 {
		padding-left: 1rem !important
	}

	.p-lg-4 {
		padding: 1.5rem !important
	}

	.pt-lg-4, .py-lg-4 {
		padding-top: 1.5rem !important
	}

	.pr-lg-4, .px-lg-4 {
		padding-right: 1.5rem !important
	}

	.pb-lg-4, .py-lg-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-lg-4, .px-lg-4 {
		padding-left: 1.5rem !important
	}

	.p-lg-5 {
		padding: 2rem !important
	}

	.pt-lg-5, .py-lg-5 {
		padding-top: 2rem !important
	}

	.pr-lg-5, .px-lg-5 {
		padding-right: 2rem !important
	}

	.pb-lg-5, .py-lg-5 {
		padding-bottom: 2rem !important
	}

	.pl-lg-5, .px-lg-5 {
		padding-left: 2rem !important
	}

	.p-lg-6 {
		padding: 2.5rem !important
	}

	.pt-lg-6, .py-lg-6 {
		padding-top: 2.5rem !important
	}

	.pr-lg-6, .px-lg-6 {
		padding-right: 2.5rem !important
	}

	.pb-lg-6, .py-lg-6 {
		padding-bottom: 2.5rem !important
	}

	.pl-lg-6, .px-lg-6 {
		padding-left: 2.5rem !important
	}

	.p-lg-7 {
		padding: 3rem !important
	}

	.pt-lg-7, .py-lg-7 {
		padding-top: 3rem !important
	}

	.pr-lg-7, .px-lg-7 {
		padding-right: 3rem !important
	}

	.pb-lg-7, .py-lg-7 {
		padding-bottom: 3rem !important
	}

	.pl-lg-7, .px-lg-7 {
		padding-left: 3rem !important
	}

	.p-lg-8 {
		padding: 3.5rem !important
	}

	.pt-lg-8, .py-lg-8 {
		padding-top: 3.5rem !important
	}

	.pr-lg-8, .px-lg-8 {
		padding-right: 3.5rem !important
	}

	.pb-lg-8, .py-lg-8 {
		padding-bottom: 3.5rem !important
	}

	.pl-lg-8, .px-lg-8 {
		padding-left: 3.5rem !important
	}

	.p-lg-9 {
		padding: 4rem !important
	}

	.pt-lg-9, .py-lg-9 {
		padding-top: 4rem !important
	}

	.pr-lg-9, .px-lg-9 {
		padding-right: 4rem !important
	}

	.pb-lg-9, .py-lg-9 {
		padding-bottom: 4rem !important
	}

	.pl-lg-9, .px-lg-9 {
		padding-left: 4rem !important
	}

	.p-lg-10 {
		padding: 4.5rem !important
	}

	.pt-lg-10, .py-lg-10 {
		padding-top: 4.5rem !important
	}

	.pr-lg-10, .px-lg-10 {
		padding-right: 4.5rem !important
	}

	.pb-lg-10, .py-lg-10 {
		padding-bottom: 4.5rem !important
	}

	.pl-lg-10, .px-lg-10 {
		padding-left: 4.5rem !important
	}

	.p-lg-11 {
		padding: 5rem !important
	}

	.pt-lg-11, .py-lg-11 {
		padding-top: 5rem !important
	}

	.pr-lg-11, .px-lg-11 {
		padding-right: 5rem !important
	}

	.pb-lg-11, .py-lg-11 {
		padding-bottom: 5rem !important
	}

	.pl-lg-11, .px-lg-11 {
		padding-left: 5rem !important
	}

	.m-lg-n1 {
		margin: -.25rem !important
	}

	.mt-lg-n1, .my-lg-n1 {
		margin-top: -.25rem !important
	}

	.mr-lg-n1, .mx-lg-n1 {
		margin-right: -.25rem !important
	}

	.mb-lg-n1, .my-lg-n1 {
		margin-bottom: -.25rem !important
	}

	.ml-lg-n1, .mx-lg-n1 {
		margin-left: -.25rem !important
	}

	.m-lg-n2 {
		margin: -.5rem !important
	}

	.mt-lg-n2, .my-lg-n2 {
		margin-top: -.5rem !important
	}

	.mr-lg-n2, .mx-lg-n2 {
		margin-right: -.5rem !important
	}

	.mb-lg-n2, .my-lg-n2 {
		margin-bottom: -.5rem !important
	}

	.ml-lg-n2, .mx-lg-n2 {
		margin-left: -.5rem !important
	}

	.m-lg-n3 {
		margin: -1rem !important
	}

	.mt-lg-n3, .my-lg-n3 {
		margin-top: -1rem !important
	}

	.mr-lg-n3, .mx-lg-n3 {
		margin-right: -1rem !important
	}

	.mb-lg-n3, .my-lg-n3 {
		margin-bottom: -1rem !important
	}

	.ml-lg-n3, .mx-lg-n3 {
		margin-left: -1rem !important
	}

	.m-lg-n4 {
		margin: -1.5rem !important
	}

	.mt-lg-n4, .my-lg-n4 {
		margin-top: -1.5rem !important
	}

	.mr-lg-n4, .mx-lg-n4 {
		margin-right: -1.5rem !important
	}

	.mb-lg-n4, .my-lg-n4 {
		margin-bottom: -1.5rem !important
	}

	.ml-lg-n4, .mx-lg-n4 {
		margin-left: -1.5rem !important
	}

	.m-lg-n5 {
		margin: -2rem !important
	}

	.mt-lg-n5, .my-lg-n5 {
		margin-top: -2rem !important
	}

	.mr-lg-n5, .mx-lg-n5 {
		margin-right: -2rem !important
	}

	.mb-lg-n5, .my-lg-n5 {
		margin-bottom: -2rem !important
	}

	.ml-lg-n5, .mx-lg-n5 {
		margin-left: -2rem !important
	}

	.m-lg-n6 {
		margin: -2.5rem !important
	}

	.mt-lg-n6, .my-lg-n6 {
		margin-top: -2.5rem !important
	}

	.mr-lg-n6, .mx-lg-n6 {
		margin-right: -2.5rem !important
	}

	.mb-lg-n6, .my-lg-n6 {
		margin-bottom: -2.5rem !important
	}

	.ml-lg-n6, .mx-lg-n6 {
		margin-left: -2.5rem !important
	}

	.m-lg-n7 {
		margin: -3rem !important
	}

	.mt-lg-n7, .my-lg-n7 {
		margin-top: -3rem !important
	}

	.mr-lg-n7, .mx-lg-n7 {
		margin-right: -3rem !important
	}

	.mb-lg-n7, .my-lg-n7 {
		margin-bottom: -3rem !important
	}

	.ml-lg-n7, .mx-lg-n7 {
		margin-left: -3rem !important
	}

	.m-lg-n8 {
		margin: -3.5rem !important
	}

	.mt-lg-n8, .my-lg-n8 {
		margin-top: -3.5rem !important
	}

	.mr-lg-n8, .mx-lg-n8 {
		margin-right: -3.5rem !important
	}

	.mb-lg-n8, .my-lg-n8 {
		margin-bottom: -3.5rem !important
	}

	.ml-lg-n8, .mx-lg-n8 {
		margin-left: -3.5rem !important
	}

	.m-lg-n9 {
		margin: -4rem !important
	}

	.mt-lg-n9, .my-lg-n9 {
		margin-top: -4rem !important
	}

	.mr-lg-n9, .mx-lg-n9 {
		margin-right: -4rem !important
	}

	.mb-lg-n9, .my-lg-n9 {
		margin-bottom: -4rem !important
	}

	.ml-lg-n9, .mx-lg-n9 {
		margin-left: -4rem !important
	}

	.m-lg-n10 {
		margin: -4.5rem !important
	}

	.mt-lg-n10, .my-lg-n10 {
		margin-top: -4.5rem !important
	}

	.mr-lg-n10, .mx-lg-n10 {
		margin-right: -4.5rem !important
	}

	.mb-lg-n10, .my-lg-n10 {
		margin-bottom: -4.5rem !important
	}

	.ml-lg-n10, .mx-lg-n10 {
		margin-left: -4.5rem !important
	}

	.m-lg-n11 {
		margin: -5rem !important
	}

	.mt-lg-n11, .my-lg-n11 {
		margin-top: -5rem !important
	}

	.mr-lg-n11, .mx-lg-n11 {
		margin-right: -5rem !important
	}

	.mb-lg-n11, .my-lg-n11 {
		margin-bottom: -5rem !important
	}

	.ml-lg-n11, .mx-lg-n11 {
		margin-left: -5rem !important
	}

	.m-lg-auto {
		margin: auto !important
	}

	.mt-lg-auto, .my-lg-auto {
		margin-top: auto !important
	}

	.mr-lg-auto, .mx-lg-auto {
		margin-right: auto !important
	}

	.mb-lg-auto, .my-lg-auto {
		margin-bottom: auto !important
	}

	.ml-lg-auto, .mx-lg-auto {
		margin-left: auto !important
	}

}

@media (min-width:1200px) {
	.m-xl-0 {
		margin: 0 !important
	}

	.mt-xl-0, .my-xl-0 {
		margin-top: 0 !important
	}

	.mr-xl-0, .mx-xl-0 {
		margin-right: 0 !important
	}

	.mb-xl-0, .my-xl-0 {
		margin-bottom: 0 !important
	}

	.ml-xl-0, .mx-xl-0 {
		margin-left: 0 !important
	}

	.m-xl-1 {
		margin: .25rem !important
	}

	.mt-xl-1, .my-xl-1 {
		margin-top: .25rem !important
	}

	.mr-xl-1, .mx-xl-1 {
		margin-right: .25rem !important
	}

	.mb-xl-1, .my-xl-1 {
		margin-bottom: .25rem !important
	}

	.ml-xl-1, .mx-xl-1 {
		margin-left: .25rem !important
	}

	.m-xl-2 {
		margin: .5rem !important
	}

	.mt-xl-2, .my-xl-2 {
		margin-top: .5rem !important
	}

	.mr-xl-2, .mx-xl-2 {
		margin-right: .5rem !important
	}

	.mb-xl-2, .my-xl-2 {
		margin-bottom: .5rem !important
	}

	.ml-xl-2, .mx-xl-2 {
		margin-left: .5rem !important
	}

	.m-xl-3 {
		margin: 1rem !important
	}

	.mt-xl-3, .my-xl-3 {
		margin-top: 1rem !important
	}

	.mr-xl-3, .mx-xl-3 {
		margin-right: 1rem !important
	}

	.mb-xl-3, .my-xl-3 {
		margin-bottom: 1rem !important
	}

	.ml-xl-3, .mx-xl-3 {
		margin-left: 1rem !important
	}

	.m-xl-4 {
		margin: 1.5rem !important
	}

	.mt-xl-4, .my-xl-4 {
		margin-top: 1.5rem !important
	}

	.mr-xl-4, .mx-xl-4 {
		margin-right: 1.5rem !important
	}

	.mb-xl-4, .my-xl-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-xl-4, .mx-xl-4 {
		margin-left: 1.5rem !important
	}

	.m-xl-5 {
		margin: 2rem !important
	}

	.mt-xl-5, .my-xl-5 {
		margin-top: 2rem !important
	}

	.mr-xl-5, .mx-xl-5 {
		margin-right: 2rem !important
	}

	.mb-xl-5, .my-xl-5 {
		margin-bottom: 2rem !important
	}

	.ml-xl-5, .mx-xl-5 {
		margin-left: 2rem !important
	}

	.m-xl-6 {
		margin: 2.5rem !important
	}

	.mt-xl-6, .my-xl-6 {
		margin-top: 2.5rem !important
	}

	.mr-xl-6, .mx-xl-6 {
		margin-right: 2.5rem !important
	}

	.mb-xl-6, .my-xl-6 {
		margin-bottom: 2.5rem !important
	}

	.ml-xl-6, .mx-xl-6 {
		margin-left: 2.5rem !important
	}

	.m-xl-7 {
		margin: 3rem !important
	}

	.mt-xl-7, .my-xl-7 {
		margin-top: 3rem !important
	}

	.mr-xl-7, .mx-xl-7 {
		margin-right: 3rem !important
	}

	.mb-xl-7, .my-xl-7 {
		margin-bottom: 3rem !important
	}

	.ml-xl-7, .mx-xl-7 {
		margin-left: 3rem !important
	}

	.m-xl-8 {
		margin: 3.5rem !important
	}

	.mt-xl-8, .my-xl-8 {
		margin-top: 3.5rem !important
	}

	.mr-xl-8, .mx-xl-8 {
		margin-right: 3.5rem !important
	}

	.mb-xl-8, .my-xl-8 {
		margin-bottom: 3.5rem !important
	}

	.ml-xl-8, .mx-xl-8 {
		margin-left: 3.5rem !important
	}

	.m-xl-9 {
		margin: 4rem !important
	}

	.mt-xl-9, .my-xl-9 {
		margin-top: 4rem !important
	}

	.mr-xl-9, .mx-xl-9 {
		margin-right: 4rem !important
	}

	.mb-xl-9, .my-xl-9 {
		margin-bottom: 4rem !important
	}

	.ml-xl-9, .mx-xl-9 {
		margin-left: 4rem !important
	}

	.m-xl-10 {
		margin: 4.5rem !important
	}

	.mt-xl-10, .my-xl-10 {
		margin-top: 4.5rem !important
	}

	.mr-xl-10, .mx-xl-10 {
		margin-right: 4.5rem !important
	}

	.mb-xl-10, .my-xl-10 {
		margin-bottom: 4.5rem !important
	}

	.ml-xl-10, .mx-xl-10 {
		margin-left: 4.5rem !important
	}

	.m-xl-11 {
		margin: 5rem !important
	}

	.mt-xl-11, .my-xl-11 {
		margin-top: 5rem !important
	}

	.mr-xl-11, .mx-xl-11 {
		margin-right: 5rem !important
	}

	.mb-xl-11, .my-xl-11 {
		margin-bottom: 5rem !important
	}

	.ml-xl-11, .mx-xl-11 {
		margin-left: 5rem !important
	}

	.p-xl-0 {
		padding: 0 !important
	}

	.pt-xl-0, .py-xl-0 {
		padding-top: 0 !important
	}

	.pr-xl-0, .px-xl-0 {
		padding-right: 0 !important
	}

	.pb-xl-0, .py-xl-0 {
		padding-bottom: 0 !important
	}

	.pl-xl-0, .px-xl-0 {
		padding-left: 0 !important
	}

	.p-xl-1 {
		padding: .25rem !important
	}

	.pt-xl-1, .py-xl-1 {
		padding-top: .25rem !important
	}

	.pr-xl-1, .px-xl-1 {
		padding-right: .25rem !important
	}

	.pb-xl-1, .py-xl-1 {
		padding-bottom: .25rem !important
	}

	.pl-xl-1, .px-xl-1 {
		padding-left: .25rem !important
	}

	.p-xl-2 {
		padding: .5rem !important
	}

	.pt-xl-2, .py-xl-2 {
		padding-top: .5rem !important
	}

	.pr-xl-2, .px-xl-2 {
		padding-right: .5rem !important
	}

	.pb-xl-2, .py-xl-2 {
		padding-bottom: .5rem !important
	}

	.pl-xl-2, .px-xl-2 {
		padding-left: .5rem !important
	}

	.p-xl-3 {
		padding: 1rem !important
	}

	.pt-xl-3, .py-xl-3 {
		padding-top: 1rem !important
	}

	.pr-xl-3, .px-xl-3 {
		padding-right: 1rem !important
	}

	.pb-xl-3, .py-xl-3 {
		padding-bottom: 1rem !important
	}

	.pl-xl-3, .px-xl-3 {
		padding-left: 1rem !important
	}

	.p-xl-4 {
		padding: 1.5rem !important
	}

	.pt-xl-4, .py-xl-4 {
		padding-top: 1.5rem !important
	}

	.pr-xl-4, .px-xl-4 {
		padding-right: 1.5rem !important
	}

	.pb-xl-4, .py-xl-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-xl-4, .px-xl-4 {
		padding-left: 1.5rem !important
	}

	.p-xl-5 {
		padding: 2rem !important
	}

	.pt-xl-5, .py-xl-5 {
		padding-top: 2rem !important
	}

	.pr-xl-5, .px-xl-5 {
		padding-right: 2rem !important
	}

	.pb-xl-5, .py-xl-5 {
		padding-bottom: 2rem !important
	}

	.pl-xl-5, .px-xl-5 {
		padding-left: 2rem !important
	}

	.p-xl-6 {
		padding: 2.5rem !important
	}

	.pt-xl-6, .py-xl-6 {
		padding-top: 2.5rem !important
	}

	.pr-xl-6, .px-xl-6 {
		padding-right: 2.5rem !important
	}

	.pb-xl-6, .py-xl-6 {
		padding-bottom: 2.5rem !important
	}

	.pl-xl-6, .px-xl-6 {
		padding-left: 2.5rem !important
	}

	.p-xl-7 {
		padding: 3rem !important
	}

	.pt-xl-7, .py-xl-7 {
		padding-top: 3rem !important
	}

	.pr-xl-7, .px-xl-7 {
		padding-right: 3rem !important
	}

	.pb-xl-7, .py-xl-7 {
		padding-bottom: 3rem !important
	}

	.pl-xl-7, .px-xl-7 {
		padding-left: 3rem !important
	}

	.p-xl-8 {
		padding: 3.5rem !important
	}

	.pt-xl-8, .py-xl-8 {
		padding-top: 3.5rem !important
	}

	.pr-xl-8, .px-xl-8 {
		padding-right: 3.5rem !important
	}

	.pb-xl-8, .py-xl-8 {
		padding-bottom: 3.5rem !important
	}

	.pl-xl-8, .px-xl-8 {
		padding-left: 3.5rem !important
	}

	.p-xl-9 {
		padding: 4rem !important
	}

	.pt-xl-9, .py-xl-9 {
		padding-top: 4rem !important
	}

	.pr-xl-9, .px-xl-9 {
		padding-right: 4rem !important
	}

	.pb-xl-9, .py-xl-9 {
		padding-bottom: 4rem !important
	}

	.pl-xl-9, .px-xl-9 {
		padding-left: 4rem !important
	}

	.p-xl-10 {
		padding: 4.5rem !important
	}

	.pt-xl-10, .py-xl-10 {
		padding-top: 4.5rem !important
	}

	.pr-xl-10, .px-xl-10 {
		padding-right: 4.5rem !important
	}

	.pb-xl-10, .py-xl-10 {
		padding-bottom: 4.5rem !important
	}

	.pl-xl-10, .px-xl-10 {
		padding-left: 4.5rem !important
	}

	.p-xl-11 {
		padding: 5rem !important
	}

	.pt-xl-11, .py-xl-11 {
		padding-top: 5rem !important
	}

	.pr-xl-11, .px-xl-11 {
		padding-right: 5rem !important
	}

	.pb-xl-11, .py-xl-11 {
		padding-bottom: 5rem !important
	}

	.pl-xl-11, .px-xl-11 {
		padding-left: 5rem !important
	}

	.m-xl-n1 {
		margin: -.25rem !important
	}

	.mt-xl-n1, .my-xl-n1 {
		margin-top: -.25rem !important
	}

	.mr-xl-n1, .mx-xl-n1 {
		margin-right: -.25rem !important
	}

	.mb-xl-n1, .my-xl-n1 {
		margin-bottom: -.25rem !important
	}

	.ml-xl-n1, .mx-xl-n1 {
		margin-left: -.25rem !important
	}

	.m-xl-n2 {
		margin: -.5rem !important
	}

	.mt-xl-n2, .my-xl-n2 {
		margin-top: -.5rem !important
	}

	.mr-xl-n2, .mx-xl-n2 {
		margin-right: -.5rem !important
	}

	.mb-xl-n2, .my-xl-n2 {
		margin-bottom: -.5rem !important
	}

	.ml-xl-n2, .mx-xl-n2 {
		margin-left: -.5rem !important
	}

	.m-xl-n3 {
		margin: -1rem !important
	}

	.mt-xl-n3, .my-xl-n3 {
		margin-top: -1rem !important
	}

	.mr-xl-n3, .mx-xl-n3 {
		margin-right: -1rem !important
	}

	.mb-xl-n3, .my-xl-n3 {
		margin-bottom: -1rem !important
	}

	.ml-xl-n3, .mx-xl-n3 {
		margin-left: -1rem !important
	}

	.m-xl-n4 {
		margin: -1.5rem !important
	}

	.mt-xl-n4, .my-xl-n4 {
		margin-top: -1.5rem !important
	}

	.mr-xl-n4, .mx-xl-n4 {
		margin-right: -1.5rem !important
	}

	.mb-xl-n4, .my-xl-n4 {
		margin-bottom: -1.5rem !important
	}

	.ml-xl-n4, .mx-xl-n4 {
		margin-left: -1.5rem !important
	}

	.m-xl-n5 {
		margin: -2rem !important
	}

	.mt-xl-n5, .my-xl-n5 {
		margin-top: -2rem !important
	}

	.mr-xl-n5, .mx-xl-n5 {
		margin-right: -2rem !important
	}

	.mb-xl-n5, .my-xl-n5 {
		margin-bottom: -2rem !important
	}

	.ml-xl-n5, .mx-xl-n5 {
		margin-left: -2rem !important
	}

	.m-xl-n6 {
		margin: -2.5rem !important
	}

	.mt-xl-n6, .my-xl-n6 {
		margin-top: -2.5rem !important
	}

	.mr-xl-n6, .mx-xl-n6 {
		margin-right: -2.5rem !important
	}

	.mb-xl-n6, .my-xl-n6 {
		margin-bottom: -2.5rem !important
	}

	.ml-xl-n6, .mx-xl-n6 {
		margin-left: -2.5rem !important
	}

	.m-xl-n7 {
		margin: -3rem !important
	}

	.mt-xl-n7, .my-xl-n7 {
		margin-top: -3rem !important
	}

	.mr-xl-n7, .mx-xl-n7 {
		margin-right: -3rem !important
	}

	.mb-xl-n7, .my-xl-n7 {
		margin-bottom: -3rem !important
	}

	.ml-xl-n7, .mx-xl-n7 {
		margin-left: -3rem !important
	}

	.m-xl-n8 {
		margin: -3.5rem !important
	}

	.mt-xl-n8, .my-xl-n8 {
		margin-top: -3.5rem !important
	}

	.mr-xl-n8, .mx-xl-n8 {
		margin-right: -3.5rem !important
	}

	.mb-xl-n8, .my-xl-n8 {
		margin-bottom: -3.5rem !important
	}

	.ml-xl-n8, .mx-xl-n8 {
		margin-left: -3.5rem !important
	}

	.m-xl-n9 {
		margin: -4rem !important
	}

	.mt-xl-n9, .my-xl-n9 {
		margin-top: -4rem !important
	}

	.mr-xl-n9, .mx-xl-n9 {
		margin-right: -4rem !important
	}

	.mb-xl-n9, .my-xl-n9 {
		margin-bottom: -4rem !important
	}

	.ml-xl-n9, .mx-xl-n9 {
		margin-left: -4rem !important
	}

	.m-xl-n10 {
		margin: -4.5rem !important
	}

	.mt-xl-n10, .my-xl-n10 {
		margin-top: -4.5rem !important
	}

	.mr-xl-n10, .mx-xl-n10 {
		margin-right: -4.5rem !important
	}

	.mb-xl-n10, .my-xl-n10 {
		margin-bottom: -4.5rem !important
	}

	.ml-xl-n10, .mx-xl-n10 {
		margin-left: -4.5rem !important
	}

	.m-xl-n11 {
		margin: -5rem !important
	}

	.mt-xl-n11, .my-xl-n11 {
		margin-top: -5rem !important
	}

	.mr-xl-n11, .mx-xl-n11 {
		margin-right: -5rem !important
	}

	.mb-xl-n11, .my-xl-n11 {
		margin-bottom: -5rem !important
	}

	.ml-xl-n11, .mx-xl-n11 {
		margin-left: -5rem !important
	}

	.m-xl-auto {
		margin: auto !important
	}

	.mt-xl-auto, .my-xl-auto {
		margin-top: auto !important
	}

	.mr-xl-auto, .mx-xl-auto {
		margin-right: auto !important
	}

	.mb-xl-auto, .my-xl-auto {
		margin-bottom: auto !important
	}

	.ml-xl-auto, .mx-xl-auto {
		margin-left: auto !important
	}

}


.custom-py-1 { padding-top: 10rem !important; padding-bottom: 10rem !important; }
.custom-py-2 { padding-top: 15rem !important; padding-bottom: 15rem !important; }
.custom-py-3 { padding-top: 20rem !important; padding-bottom: 20rem !important; }
.custom-py-4 { padding-top: 25rem !important; padding-bottom: 25rem !important; }
.custom-py-5 { padding-top: 30rem !important; padding-bottom: 30rem !important; }
.custom-pt-1 { padding-top: 10rem !important; }
.custom-pt-2 { padding-top: 15rem !important; }
.custom-pt-3 { padding-top: 20rem !important; }
.custom-pt-4 { padding-top: 25rem !important; }
.custom-pt-5 { padding-top: 30rem !important; }
.custom-pb-1 { padding-bottom: 10rem !important; }
.custom-pb-2 { padding-bottom: 15rem !important; }
.custom-pb-3 { padding-bottom: 20rem !important; }
.custom-pb-4 { padding-bottom: 25rem !important; }
.custom-pb-5 { padding-bottom: 30rem !important; }
.z-index-1 { z-index: 99; }
.mt-n15 { margin-top: -15rem !important; }
.mt-n20 { margin-top: -20rem !important; }

/* ------------------------------------------------
  Project:   Bootsland - Creative Bootstrap 4 Landing Page
  Author:    ThemeHt
------------------------------------------------ */

body {font-family: 'Poppins', sans-serif; font-style: normal; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #1d1d33; overflow-x: hidden;}
.page-wrapper { overflow-x: hidden; }
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { margin-bottom: 0.5rem; font-weight: 300; line-height: 1.5; }
h1, .h1 { font-size: 2.5rem; }
h2, .h2 { font-size: 2rem; }
h3, .h3 { font-size: 1.75rem; }
h4, .h4 { font-size: 1.5rem; }
h5, .h5 { font-size: 1.25rem; }
h6, .h6 { font-size: 1rem; }
h4, h5, h6, .h4, .h5, .h6 { font-weight: 500; }
p { color: #8090b5; line-height: 1.7; }
.lead { font-size: 1.25rem; font-weight: 300; }
.text-dark{color: #1d1d33 !important;}
.text-primary {color: #213262 !important;}
.text-muted { color: rgb(140, 152, 164) !important }
section{padding: 5rem 0}

/* ------------------------
Transition
------------------------*/
a, i, .btn, button, img, span, .dropdown-item, .hover-translate { transition: all 0.5s ease-in-out 0s; }
.hover-translate:hover { box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15); background-color: #fff; transform: translate3d(0, -20px, 0); }
a:hover { text-decoration: none; }


/* ------------------------
Background Overlay
------------------------*/
[data-overlay] { position: relative; z-index: 0; }
[data-overlay]:before { position: absolute; content: ''; background-color: #000000; width: 100%; height: 100%; top: 0; left: 0; z-index: -1; }
[data-overlay].white-overlay:before { background-color: #fff; }
[data-overlay].theme-overlay:before { background-color: #213262; }
[data-overlay].grediant-overlay:before { background: rgb(3, 214, 101); background: linear-gradient(58deg, rgba(3, 214, 101, 1) 15%, rgba(115, 41, 194, 1) 100%); }
[data-overlay="1"]:before { opacity: 0.1; }
[data-overlay="2"]:before { opacity: 0.2; }
[data-overlay="3"]:before { opacity: 0.3; }
[data-overlay="4"]:before { opacity: 0.4; }
[data-overlay="5"]:before { opacity: 0.5; }
[data-overlay="6"]:before { opacity: 0.6; }
[data-overlay="7"]:before { opacity: 0.7; }
[data-overlay="8"]:before { opacity: 0.8; }
[data-overlay="9"]:before { opacity: 0.9; }
[data-overlay="10"]:before { opacity: 1; }
[data-overlay="0"]:before { opacity: 0; }


.font-w-3 { font-weight: 300; }
.font-w-4 { font-weight: 400; }
.font-w-5 { font-weight: 500; }
.font-w-6 { font-weight: 600; }
.font-w-7 { font-weight: 700; }
.font-w-8 { font-weight: 800; }
.font-w-9 { font-weight: 900; }



/* ------------------------
Left-Right
------------------------*/
.left-0 { left: 0; }
.right-0 { right: 0; }
.top-0 { top: 0; }
.bottom-0 { bottom: 0; }
.left-50 { left: 50%; transform: translateX(-50%); -webkit-transform: translateX(-50%); -o-transform: translateX(-50%); -ms-transform: translateX(-50%); -moz-transform: translateX(-50%); }
.top-50 { top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); }


/* ------------------------
Pre Loader
------------------------*/
#ht-preloader { background: #ffffff; bottom: 0; height: 100%; left: 0; overflow: hidden !important; position: fixed; right: 0; text-align: center; top: 0; width: 100%; z-index: 99999; }
.clear-loader { transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); -ms-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); z-index: 999; box-sizing: border-box; display: inline-block; left: 50%; position: absolute; text-align: center; top: 50%; }
.loader-inner { --path: #213262; --dot: #5628ee; --duration: 3s; width: 88px; height: 88px; position: relative; }
.loader-inner:before { content: ''; width: 12px; height: 12px; border-radius: 50%; position: absolute; display: block; background: #f94f15; top: 74px; left: 38px; transform: translate(-36px, -36px); animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite; }
.loader-inner svg { display: block; width: 100%; height: 100%; }
.loader-inner svg rect { fill: none; stroke: var(--path); stroke-width: 10px; stroke-linejoin: round; stroke-linecap: round; }
.loader-inner svg rect { stroke-dasharray: 192 64 192 64; stroke-dashoffset: 0; animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite; }
@keyframes pathRect {
	25% { stroke-dashoffset: 64; }
	50% { stroke-dashoffset: 128; }
	75% { stroke-dashoffset: 192; }
	100% { stroke-dashoffset: 256; }
}
@keyframes dotRect {
	25% { transform: translate(0, 0); }
	50% { transform: translate(36px, -36px); }
	75% { transform: translate(0, -72px); }
	100% { transform: translate(-36px, -36px); }
}


/* ------------------------
Button
------------------------*/
.btn { font-weight: 500; padding: 0.75rem 1rem; }
.btn-primary { color: #fff; background-color: #213262; border-color: #213262; }
.btn-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle { color: #fff; background-color: #f94f15; border-color: #f94f15; }
.btn-outline-primary { color: #213262; border-color: #213262; }
.btn-outline-primary:hover { background-color: #f94f15; border-color: #f94f15; }
.btn-dark { background-color: #060927; border-color: #060927; }
.btn-dark:hover { background-color: #f94f15; border-color: #f94f15; }
.btn.focus, .btn:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus { box-shadow: none; }
button:focus { outline: none; }

/* ------------------------
Background 
------------------------*/
.bg-primary { background-color: #212227 !important; }
.bg-light { background-color: #f5faff !important; }
.bg-dark { background-color: #060927 !important; }
.bg-orange { background-color: #f94f15 !important }
.bg-primary-soft { background-color: rgba(19, 96, 239, 0.01); }
.bg-warning-soft { background-color: rgba(250, 215, 118, .1); }


/* ------------------------
Badge
------------------------*/
.badge-dark { background-color: #060927 !important; }
.badge-primary-soft { background-color: rgba(19, 96, 239, .1); color: #213262 }
.badge-secondary-soft { background-color: rgba(80, 102, 144, .1); color: #506690 }
.badge-success-soft { background-color: rgba(40, 167, 69, .1); color: #28a745 }
.badge-info-soft { background-color: rgba(23, 162, 184, .1); color: #17a2b8 }
.badge-warning-soft { background-color: rgba(255, 193, 7, .1); color: #ffc107 }
.badge-danger-soft { background-color: rgba(220, 53, 69, .1); color: #dc3545 }
.badge-light-soft { background-color: rgba(245, 250, 255, .1); color: #f5faff }
.badge-dark-soft { background-color: rgba(6, 9, 39, .1); color: #060927 }


/* ------------------------
Icon
------------------------*/
.f-icon-shape-sm { width: 4rem; height: 4rem; line-height: 4rem; display: inline-block; align-items: center; justify-content: center; text-align: center; }
.f-icon-shape { width: 6rem; height: 6rem; line-height: 6rem; display: inline-block; align-items: center; justify-content: center; text-align: center; }
.ic-2x { font-size: 2em; }
.ic-3x { font-size: 3em; }
.ic-5x { font-size: 5em; }
.ic-7x { font-size: 7em; }

/* ------------------------
Shadow
------------------------*/
.shadow-primary { box-shadow: 0 0.5rem 1rem rgba(110, 0, 255, .15) !important; }
.shadow-sm { box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important; }
.shadow { box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15) !important; }
.shadow-lg { box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important; }
.shadow-none { box-shadow: none !important; }


/* ------------------------
  Header
------------------------*/
.site-header.navbar-dark .navbar-nav .nav-link { color: #ffffff; }
.site-header.navbar-dark .navbar-nav .nav-link:focus, .site-header.navbar-dark .navbar-nav .nav-link:hover { color: #f94f15; }
#header-wrap { padding: 10px 0; position: relative; }
#header-wrap.fixed-header { padding: 5px 0; position: fixed !important; top: 0; left: 0; width: 100%; z-index: 999 !important; background: #ffffff !important; box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15) !important; animation: smoothScroll 1s forwards; }

/*--navigation--*/
.navbar { padding: 0; }
.navbar-nav .nav-item.dropdown .dropdown-menu { background: #ffffff; padding: 0; border: none; margin-top: 1rem; box-shadow: 0 0.5rem 1rem rgba(110, 0, 255, .15) !important; }
.navbar-brand { margin-right: 0; }
.navbar-brand { margin-right: 0; }
header .navbar-nav .nav-item { margin: 0 15px }
.navbar-nav .nav-link { font-weight: 500; padding: 0 !important; border-radius: 0; text-transform: capitalize; position: relative; }
.navbar-dark #header-wrap.fixed-header { background: #213262 !important }
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .show > .nav-link, .dropdown-item:focus, .dropdown-item:hover { color: #213262; }

.dropdown-item:focus, .dropdown-item:hover{background: #213262; color: #ffffff;}
.navbar-dark .navbar-nav .nav-link {color: rgba(255,255,255,.8);}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {color: rgba(255,255,255,1);}
.dropdown-submenu { position: relative; }
.dropdown-submenu a::after { transform: translateY(-50%) rotate(-90deg); position: absolute; right: 1.5rem; top: 50%; }
.dropdown-submenu .dropdown-menu { top: 0; left: 100%; margin-left: .1rem; margin-right: .1rem; margin-top: 0 !important;}
.dropdown-submenu .dropdown-item { padding-right: 2.5rem; position: relative; }
.dropdown-item { padding: .50rem 1.5rem; font-weight: 400; color: #8090b5; font-size: 0.9rem }

@media (max-width:991.98px) {
/*Header*/
#header-wrap { padding: 15px 0; }
#header-wrap.fixed-header { padding: 15px 0; }
.navbar { justify-content: flex-end; width: 100%; position: relative !important; }
.navbar-brand { left: 15px; z-index: 1; margin-right: 0; position: absolute; top: 50%; padding: 0; transform: translateY(-50%); -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); }
.navbar-toggler { background: none; height: 40px; width: 40px; padding: 0; border-radius: 0; margin-right: 0; }
.site-header .navbar-nav ul { position: static; }
.site-header .navbar-nav .nav-item { margin: 0; }
.site-header .navbar-nav .nav-link { padding: 10px !important; border-radius: 0; }
.navbar-nav a .sub-arrow { position: absolute; top: 50%; right: 0; margin: -0.7em 0.5em 0 0; border-radius: .25rem; padding: 0; width: 2em; height: 1.4em; font-size: 1.25rem; line-height: 1.2em; text-align: center; }
.navbar-collapse { border-radius: 7px; background: #ffffff; max-height: 400px; left: 0; padding: 10px; position: absolute; z-index: 99; top: 50px; width: 100%; overflow: auto; border: medium none; box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15); }
.navbar-nav .dropdown-toggle::after { position: absolute; top: 50%; right: 0; margin: -.1rem 0.5em 0 0; }
.site-header.navbar-dark .navbar-nav .nav-link{color: rgba(0,0,0,.5)}
.site-header.navbar-dark .navbar-nav .nav-link.active, .site-header.navbar-dark .navbar-nav .nav-link:focus, .site-header.navbar-dark .navbar-nav .nav-link:hover{ color: #213262; }
}


.shape-1 { position: absolute; top: -1px; width: 100%; left: 0; right: 0; }
.shape-2 { position: absolute; right: 0; top: 0; height: 100%; width: 100%; }
.shape-1.bottom { top: inherit; bottom: -1px; }
.round-shape { -webkit-clip-path: circle(100% at 50% -50%); clip-path: circle(100% at 50% -50%); }

/* ------------------------
Contact
------------------------*/
.form-control { height: 3rem; font-size: 0.8rem; }
.input-1 { border: 1px solid #d0d7e7; }
.input-2 { border: none; background: none; box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15) !important; }
.form-control:focus { color: #495057; background-color: none; border-color: #213262; outline: 0; box-shadow: none; }
.form-group { margin-bottom: 1.3rem; position: relative; }
.help-block.with-errors { position: absolute; color: red; right: 15px; font-size: 10px; line-height: 14px; }


/* ------------------------
Tab
------------------------*/
.nav-tabs .nav-item.nav-link { padding: 1.5rem 2rem; font-size: 1.2rem; color: #1d1d33; margin: 0 5px; border-radius: .25rem; box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15) !important; }
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover { background: #213262; color: #ffffff; }
@media (max-width:575.98px) {
	.nav-tabs .nav-item.nav-link { padding: .5rem 1rem; }
}


/* ------------------------
Video
------------------------*/
.video-btn { position: relative; display: inline-block; }
.play-btn { font-size: 30px; background: #ffffff; color: #213262; line-height: 60px; width: 70px; height: 70px; border: 10px solid #ffffff; border-radius: 100%; text-align: center; cursor: pointer; z-index: 9; position: relative; display: inline-block; transition: .15s ease-in-out; transition-property: color, background, border-color; }
.video-btn-pos { position: absolute; position: absolute; left: 50%; top: 50%; width: auto !important; z-index: 99; transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); -ms-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); }
.html5-video { height: 100%; left: 0; opacity: 1; overflow: hidden; position: absolute; top: 0; width: 100%; z-index: -2; }
.html5-video video { width: 100%; }
.video-btn .spinner-eff { position: absolute; width: 200px; height: 200px; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); -ms-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); }

/* ------------------------
Spinner
------------------------*/
.spinner-eff .spinner-circle { border-radius: 100px; position: absolute; left: 0; right: 0; margin: auto; transform: scale(1); transform-origin: center center; }
.spinner-eff .circle-1 { width: 100%; height: 100%; background-color: #213262; top: 0; animation: pulse 1.6s linear 0s infinite; }
.spinner-eff .circle-2 { width: 66.6%; height: 66.6%; background-color: #213262; top: 16.5%; animation: pulse-2 1.6s linear 0s infinite; }

/* ------------------------
Portfolio
------------------------*/
.portfolio-filter { display: inline-block; margin: 0 auto 2rem; text-align: center; }
.portfolio-filter button { position: relative; background: none; padding: 8px 15px; border-radius: .25rem; cursor: pointer; border: none; font-weight: normal; color: #1d1d33; float: left; text-transform: capitalize; }
.portfolio-filter button:focus { outline: none; outline-style: none; outline-offset: 0; }
.portfolio-filter button.is-checked, .portfolio-filter button:hover { background: #213262; color: #ffffff; box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15) !important; }
.portfolio-filter button + button { margin-left: 0.5rem; }
.isotope .grid-item img { width: 100%; }
.portfolio-title { background: #213262; padding: 30px; width: 100%; transform: translate3d(0, 100%, 0); opacity: 0; visibility: hidden; transition: all 0.5s ease-in-out; position: absolute; left: 0; bottom: 0; z-index: 2; }
.portfolio-item:hover .portfolio-title { opacity: 1; visibility: visible; transform: translate3d(0, 0, 0); }
.portfolio-meta li { position: relative; padding-left: 60px; }
.portfolio-meta li i { position: absolute; left: 0; top: 0; color: #213262; font-size: 40px; line-height: 40px; }
.portfolio-meta li span { color: #1d1d33; display: block; font-weight: bold; text-transform: uppercase; margin-bottom: 7px; }

/* -------------------------------
 Background Position & Size
-------------------------------*/
[data-bg-img] { background-size: cover; background-position: center center; background-repeat: no-repeat; }
.bg-cover { background-size: cover; }
.bg-contain { background-size: contain; }
.bg-pos-l { background-position: left; }
.bg-pos-r { background-position: right; }
.bg-pos-rt { background-position: right top; }
.bg-pos-lt { background-position: left top; }
.bg-pos-rb { background-position: right bottom; }
.bg-pos-lb { background-position: left bottom; }
.bg-repeat { background-repeat: repeat; }
.bg-pos-b { background-position: bottom; }
.heroparallax {
    position: absolute;
    bottom: 60px;
    right: -10%;
    width: 50%;
}
.simpleParallax{overflow: inherit !important;}


.product-link a.add-cart { width: auto; height: auto; padding: 0px 20px; line-height: 36px; font-size: 14px; }
.product-link a i { display: inline-block; color: #000; font-size: 18px; vertical-align: middle; }
.product-link a { display: inline-table; width: 36px; height: 36px; background: #fff; border-radius: 60px; line-height: 36px; overflow: hidden; color: #000; position: relative; box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .15); transition: all .4s ease; text-align: center; }
.product-link a:hover { background: #213262; color: #ffffff; }
.product-link a:hover i { color: #ffffff; }
.size-choose input[type="radio"] { visibility: hidden; position: absolute; }
.size-choose label { display: inline-block; height: 30px; min-width: 30px; text-align: center; font-size: 14px; color: #1d1d33; font-weight: 500; cursor: pointer; border-radius: 50%; line-height: 30px; }
.size-choose input[type="radio"]:checked + label { background: #213262; color: #fff; }
.filter-color li, .size-choose li { list-style-type: none; display: inline-block; margin-right: 10px; }
.filter-color li:last-child, .size-choose li:last-child { margin-right: 0; }
[type="radio"]:checked, [type="radio"]:not(:checked) { position: absolute; opacity: 0; pointer-events: none; }
.form-check-label { width: 26px; height: 26px !important; border-radius: 50%; padding: 0 !important; }
.form-check-input[type="radio"]:checked + label, .form-check-input[type="radio"]:not(:checked) + label, label.btn input[type="radio"]:checked + label, label.btn input[type="radio"]:not(:checked) + label { position: relative; padding-left: 35px; cursor: pointer; display: inline-block; height: 1.5625rem; line-height: 1.5625rem; transition: .28s ease; -webkit-user-select: none; -ms-user-select: none; user-select: none; }
.form-check-input[type="radio"] + label::after, .form-check-input[type="radio"] + label::before, label.btn input[type="radio"] + label::after, label.btn input[type="radio"] + label::before { content: ''; position: absolute; left: -2px; top: -2px; width: 30px; height: 30px; z-index: 0; transition: .28s ease; z-index: -1; border-radius: 50%; }
.form-check-input[type="radio"]:checked + label::after, label.btn input[type="radio"]:checked + label::after { transform: scale(1.02); }
.form-check-input[type="radio"]:checked + label::after { background-color: #213262; }
input.form-product { -moz-appearance: textfield; height: 40px; border: none; background: #ffffff; text-align: center; width: 150px; border-top: 1px solid #eeeff7; border-bottom: 1px solid #eeeff7; color: #1d1d33; vertical-align: middle; }
.btn-product { background: none; color: #1d1d33; border: 1px solid #eeeff7; height: 40px; width: auto; padding: 0 10px; font-size: 13px; cursor: pointer; }

/*Range Slider*/
.ui-slider-horizontal { height: 0.2em; }
.ui-slider-horizontal .ui-slider-handle { top: -6px; height: 14px; width: 14px; border-radius: 50%; border: none; margin-left: 0; }
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active { background: #213262; }
.ui-widget-header { background: #0a1b2b }
.ui-widget.ui-widget-content { border: none; background: #f5faff; }
#amount { margin-top: 20px; width: 90px; color: #1d1d33 }
.cart-table thead th { padding: 30px; text-align: center; border-width: 1px !important; vertical-align: middle; color: #1d1d33; font-size: 18px; font-weight: 500; }
.cart-table td { padding: 20px; text-align: center; vertical-align: middle; color: #1d1d33; }


/* ------------------------
Countdown
------------------------*/
.countdown { margin: 50px 0; width: 100%; }
.countdown > li { font-weight: 700; list-style-type: none; }
.countdown > li span { font-size: 70px; font-weight: 700; line-height: 80px; display: inline-block; color: #213262; }
.countdown > li p { font-size: 20px; display: block; line-height: 30px; margin-bottom: 0; color: #1d1d33; }

/* ------------------------
Other
------------------------*/
.clients-logo img { opacity: 0.5; max-height: 50px; }
.clients-logo img:hover { opacity: 1; }
#particles-js {width: 100%; height: 100%; position: absolute; top: 0; left: 0;}
.list-group-item-action:focus, .list-group-item-action:hover {color: #213262; background-color: inherit;}
.page-link:hover {color: #ffffff; background-color: #213262; border-color: #213262;}
.link-title { color: #1d1d33 }
.link-title:hover { color: #213262 }
.line-h-0 { line-height: 0; }
.la { line-height: inherit }
.bg-dark hr,
.bg-primary hr{border-top-color: rgba(255,255,255,0.2);}

@media (max-width:991.98px) {
	.font-size-md-down-3 { font-size: 2rem; }
	.font-size-md-down-5 { font-size: 3rem; }
	.heroparallax {position: relative; bottom: inherit; right: inherit; width: 100%;}
	.cart-table .media {display: block;}
}
@media (max-width:767.98px) {
	.display-4 { font-size: calc(1.575rem + 3.9vw); }
	.parallaxie{background-position: center center !important;}
}
@media (max-width:991.98px) {
	.transform-md-rotate { transform: rotate(90deg); }
}
@media (max-width:767.98px) {
	.transform-md-rotate { background: #213262; transform: inherit; }
}


.accordion .btn {
    height: 20px;
    margin-top: -20px;
}

.Countdown {
    margin: 10px auto;
    padding-bottom: 20px;
}

.Countdown-col {
    display: inline-block;
}

.Countdown-col-element {
    display: inline-block;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
}

.Countdown-col-element strong {
    font-size: 30px;
}

.zeor21logo {
    font-size: 10pt;
    text-align: right;
    margin-top: -10px;
    margin-bottom: 0px;
}
/* ------------------------------------------------
  Project:   Bootsland - Creative Bootstrap 4 Landing Page
  Author:    ThemeHt
------------------------------------------------ */

/* ------------------------
    Table of Css

  1. Bootstrap
  2. Animate
  3. Line Awesome
  4. Themify Icons
  5. magnific-popup
  6. Owl Carousel
  7. Lightslider  
  8. Jquery Ui
  9. Spacing
 ------------------------ */
 
/* @import 'bootstrap.min.css'; */
/* @import 'animate.css'; */
/* @import 'line-awesome.min.css'; */
/* @import 'themify-icons.css'; */
/* @import 'magnific-popup.css'; */
/* @import 'owl.carousel.css'; */
/* @import 'lightslider.min.css'; */
/* @import 'jquery-ui.css'; */


